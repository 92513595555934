import React from "react";
import PriceView from "./PriceView";
import css from "./PricesView.module.css"
import {Prices} from "../../datastruct/Price";
import IntlText from "../../intl/IntlText";

export type PricesViewProps =
    { prices: Prices }
    & React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>

export default function PricesView({prices, ...tableProps}: PricesViewProps) {
    return <table {...tableProps} className={[css.prices, tableProps.className].join(" ")}>
        <tbody>
        {prices.map((p, i) => {
            return <tr key={i}>
                <td><IntlText text={p.description}/></td>
                <td><PriceView price={p.price}/></td>
            </tr>
        })}
        </tbody>
    </table>
}