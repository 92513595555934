import React, {PropsWithChildren, ReactElement} from "react";
import Title from "antd/es/typography/Title";
import css from "./CategoryView.module.css"
import IntlText, {IntlTextType} from "../intl/IntlText";

export default function CategoryView(props: PropsWithChildren<{
    name: IntlTextType,
    subtitle?: IntlTextType|ReactElement
}>) {
    return <>
        <div className={css.header}>
            <Title className={css.title} level={1}>
                <IntlText text={props.name}/>
            </Title>
            {props.subtitle && <IntlText text={props.subtitle}/>}
        </div>
        {props.children}
    </>
}
