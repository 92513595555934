import Title, {TitleProps} from "antd/es/typography/Title";
import css from "./PriceView.module.css";
import React from "react";
import {Price} from "../../datastruct/Price";
import {useIntl} from "react-intl";
import Money from "js-money";
import {Currency} from "js-money/lib/currency";

export type PriceViewProps = { price: Price } & TitleProps

export default function PriceView({price, ...props}: PriceViewProps) {
    const intl = useIntl()
    // @ts-ignore
    const currency: Currency = Money[price.getCurrency()]

    const parts = intl.formatNumberToParts(price.getAmount() / 100, {
        style: "decimal",
        minimumFractionDigits: currency.decimal_digits,
        maximumFractionDigits: currency.decimal_digits,
    })

    const decimalIndex = parts.findIndex(p => p.type === "decimal")

    const integerPart = parts.slice(0, decimalIndex).map(p => p.value).join("")
    const decimalSeparator = parts.slice(decimalIndex, decimalIndex + 1).map(p => p.value).join()
    const decimalPart = parts.slice(decimalIndex + 1).map(p => p.value).join("")

    return <Title
        level={5}
        {...props}
        className={[css.root, props.className].join(" ")}
    >
        <span translate="no">
            {currency.symbol} <span style={{fontWeight: "bold"}}>{integerPart}</span><span style={{fontWeight: "normal"}}>{decimalSeparator}<span style={{fontSize: "14px"}}>{decimalPart}</span></span>
        </span>
    </Title>
}
