import React, {ComponentType, createContext, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import './App.less';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import MenuPage from "./pages/MenuPage";
import {AppPage} from "./layout/AppPage";
import HomePage from "./pages/HomePage";
import Contacts from "./pages/Contacts";
import {FormattedMessage, IntlProvider} from "react-intl";

type Page = {
    name: ReactNode,
    component: ComponentType,
    noContent?: boolean
}

export const FACEBOOK_LINK = "https://www.facebook.com/LAlternativaPubPizzeria"
export const INSTAGRAM_LINK = "https://www.instagram.com/alternativa_pub_pizzeria"
export const MAPS_LINK = "https://www.google.com/maps/place//data=!4m2!3m1!1s0x4779353425e3e083:0xea4b423ceb0ad3bf?source=g.page.default"

export const SUPPORTED_LANGUAGES = ["it-IT", "en"]

export const MAIN_PAGES: Record<string, Page> = {
    "/": {
        name: <FormattedMessage id="home" defaultMessage="Home"/>,
        component: HomePage,
        noContent: true
    },
    "/menu": {
        name: <FormattedMessage id="menu" defaultMessage="Menu"/>,
        component: MenuPage
    },
    "/contacts": {
        name: <FormattedMessage id="about_us" defaultMessage="Contatti"/>,
        component: Contacts
    }
}

const AppContext = createContext<{
    changeLocale: (locale: string) => void
} | undefined>(undefined)

export function useApp() {
    const app = useContext(AppContext)
    if (app) {
        return app
    } else {
        throw new Error("Not in app context")
    }
}

export default function App() {
    const [locale, setLocale] = useState<string|null>()
    const [localeData, setLocaleData] = useState<Record<string, any>>()
    useEffect(() => {
        if (locale === undefined) {
            setLocale(navigator.language ?? null)
        }
    }, [locale, setLocale])

    useEffect(() => {
        if (locale !== undefined) {
            const l = locale ?? "en"
            loadLocaleData(l)
                .then(setLocaleData)
                .catch(() => console.log("Error loading locale data"))

            document.documentElement.lang = l
        }
    }, [locale, setLocaleData])

    const app = useMemo(() => ({
        changeLocale: setLocale
    }), [setLocale])

    return <AppContext.Provider value={app}>
        {localeData && <IntlProvider
            locale={localeData.locale}
            messages={localeData.messages}
            defaultLocale="it"
        >
            <BrowserRouter>
                <Switch>
                    {Object.entries(MAIN_PAGES).map(([path, page]) => {
                        return <Route key={path} path={path} exact={path === "/"}>
                            <AppPage noContent={page.noContent}>
                                <page.component/>
                            </AppPage>
                        </Route>
                    })}
                </Switch>
            </BrowserRouter>
        </IntlProvider>}
    </AppContext.Provider>
}

export async function loadLocaleData(locale: string): Promise<{ locale: string, messages: Record<string, any> }> {
    if (locale.match(/^it(-.+)?$/) !== null) {
        return {locale: 'it', messages: await import('./intl/lang/it.json')}
    } else {
        return {locale: 'en', messages: await import('./intl/lang/en.json')}
    }
}
