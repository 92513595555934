import {FancyBottledBeer} from "../../datastruct/Beers";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import css from "./FancyBottledBeerView.module.css";
import Title from "antd/es/typography/Title";
import PricesView from "../price/PricesView";
import Text from "antd/es/typography/Text";
import InfoView from "../InfoView";
import {formatAlcoholicContent} from "../../utils";
import {Divider} from "antd";
import React from "react";
import Img from "../Img";
import IntlText from "../../intl/IntlText";
import {defineMessage, useIntl} from "react-intl";

export default function FancyBottledBeerView({beer}: { beer: FancyBottledBeer }) {
    const isMobile = useBreakpoint()["xs"]
    const intl = useIntl()

    return <div>
        <div className={css.root}>
            <Img alt="" image={beer.image} className={css.bottle}/>
            <div className={css.description} style={!isMobile ? {marginLeft: "32px"} : {}}>
                <div className={css.title}>
                    <span translate="no"><Title level={4}>{beer.name}</Title></span>
                    <div style={{flexGrow: 1}}/>
                    {<PricesView prices={[beer.price]}/>}
                </div>
                <Text style={{paddingTop: "8px"}}>
                    <IntlText text={beer.description} />
                </Text>
                <InfoView
                    variant="colon"
                    name={defineMessage({id: "beer.alcoholic_content", defaultMessage: "Grado alcolico"})}
                    style={{paddingTop: "16px"}}
                    info={formatAlcoholicContent(intl, beer.alcoholContent)}
                />
            </div>
        </div>
        <Divider/>
    </div>
}