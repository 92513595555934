import React from "react";
import PricesView, {PricesViewProps} from "./PricesView";
import PriceView, {PriceViewProps} from "./PriceView";
import {PriceOrPrices} from "../../datastruct/Price";

export type PriceOrPricesViewProps = { price: PriceOrPrices } & Omit<PriceViewProps, "price"> & Omit<PricesViewProps, "prices">

export default function PriceOrPricesView({price, ...rest}: PriceOrPricesViewProps) {
    if (Array.isArray(price)) {
        return <PricesView prices={price} {...rest}/>
    } else {
        return <PriceView price={price} {...rest}/>
    }
}