import React from "react";
import {MenuSubCategory} from "../datastruct/MenuEntries";
import MenuEntryView from "./MenuEntryView";
import CategoryView from "./CategoryView";
import css from "./MenuSubCategoryView.module.css"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useIntlText} from "../intl/IntlText";

export default function MenuSubCategoryView({subCategory}: { subCategory: MenuSubCategory }) {
    const isMobile = !useBreakpoint()["md"]
    const key = useIntlText(subCategory.name)

    return <CategoryView
        key={key}
        name={subCategory.name}
        subtitle={subCategory.subtitle}
    >
        <div className={[css.grid, isMobile ? css.gridMobile : undefined].join(" ")}>
            {subCategory.entries.map((item, i) => {
                return <MenuEntryView key={i} entry={item}/>
            })}
        </div>
    </CategoryView>
}