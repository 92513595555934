import {defineMessage, IntlShape} from "react-intl";
import {IntlTextType, resolveIntlText} from "./IntlText";

export type Ingredient = keyof typeof INGREDIENTS
export type IngredientOrText = Ingredient | { text: IntlTextType }

const INGREDIENTS = {
    "anchovies": defineMessage({id: "ingredient.anchovies", defaultMessage: "acciughe"}),
    "artichokes": defineMessage({id: "ingredient.artichokes", defaultMessage: "carciofi"}),
    "asiago": defineMessage({id: "ingredient.asiago", defaultMessage: "asiago"}),
    "asparagus": defineMessage({id: "ingredient.asparagus", defaultMessage: "asparagi"}),
    "bacon": defineMessage({id: "ingredient.bacon", defaultMessage: "pancetta"}),
    "barbecue_sauce": defineMessage({id: "ingredient.barbecue_sauce", defaultMessage: "salsa barbecue"}),
    "battered_vegetables": defineMessage({id: "ingredient.battered_vegetables", defaultMessage: "verdure pastellate"}),
    "bresaola": defineMessage({id: "ingredient.bresaola", defaultMessage: "bresaola"}),
    "brewers_yeast": defineMessage({id: "ingredient.brewers_yeast", defaultMessage: "lievito di birra"}),
    "brie": defineMessage({id: "ingredient.brie", defaultMessage: "brie"}),
    "burrata": defineMessage({id: "ingredient.burrata", defaultMessage: "burrata"}),
    "capers": defineMessage({id: "ingredient.capers", defaultMessage: "capperi"}),
    "caramel": defineMessage({id: "ingredient.caramel", defaultMessage: "caramello"}),
    "carrots": defineMessage({id: "ingredient.carrots", defaultMessage: "carote"}),
    "cheese": defineMessage({id: "ingredient.cheese", defaultMessage: "formaggio"}),
    "cherry_tomatoes": defineMessage({id: "ingredient.cherry_tomatoes", defaultMessage: "pomodori ciliegini"}),
    "chili_pepper": defineMessage({id: "ingredient.chili_pepper", defaultMessage: "peperoncino"}),
    "chopped_pistachios": defineMessage({id: "ingredient.chopped_pistachios", defaultMessage: "granella di pistacchio"}),
    "cocktail_sauce": defineMessage({id: "ingredient.cocktail_sauce", defaultMessage: "salsa rosa"}),
    "common_wheat_type_0": defineMessage({id: "ingredient.common_wheat_type_0", defaultMessage: 'Farina di grano tenero tipo "0"'}),
    "cooked_ham": defineMessage({id: "ingredient.cooked_ham", defaultMessage: "prosciutto cotto"}),
    "corn": defineMessage({id: "ingredient.corn", defaultMessage: "mais"}),
    "country_fries": defineMessage({id: "ingredient.country_fries", defaultMessage: "patatine country"}),
    "cream": defineMessage({id: "ingredient.cream", defaultMessage: "panna"}),
    "cucumbers": defineMessage({id: "ingredient.cucumbers", defaultMessage: "cetrioli"}),
    "cutlet": defineMessage({id: "ingredient.cutlet", defaultMessage: "cotoletta"}),
    "dark_olives": defineMessage({id: "ingredient.dark_olives", defaultMessage: "olive nere"}),
    "dextrose_emulsifier": defineMessage({id: "ingredient.dextrose_emulsifier", defaultMessage: "destrosio emulsionante"}),
    "dried_tomatoes": defineMessage({id: "ingredient.dried_tomatoes", defaultMessage: "pomodori secchi"}),
    "edible_lard": defineMessage({id: "ingredient.edible_lard", defaultMessage: "strutto commestibile"}),
    "egg": defineMessage({id: "ingredient.egg", defaultMessage: "uovo"}),
    "eggplant": defineMessage({id: "ingredient.eggplant", defaultMessage: "melanzane"}),
    "eggs": defineMessage({id: "ingredient.eggs", defaultMessage: "uova"}),
    "extra_virgin_olive_oil": defineMessage({id: "ingredient.extra_virgin_olive_oil", defaultMessage: "olio extravergine di oliva"}),
    "fantasy_of_pizza_maker": defineMessage({id: "ingredient.fantasy_of_pizza_maker", defaultMessage: "fantasia del pizzaiolo"}),
    "french_fires": defineMessage({id: "ingredient.french_fires", defaultMessage: "patatine fritte"}),
    "friarielli": defineMessage({id: "ingredient.friarielli", defaultMessage: "friarielli"}),
    "fried_egg": defineMessage({id: "ingredient.fried_egg", defaultMessage: "uovo all'occhio"}),
    "garlic": defineMessage({id: "ingredient.garlic", defaultMessage: "aglio"}),
    "gin": defineMessage({id: "ingredient.gin", defaultMessage: "gin"}),
    "gorgonzola": defineMessage({id: "ingredient.gorgonzola", defaultMessage: "gorgonzola"}),
    "grana": defineMessage({id: "ingredient.grana", defaultMessage: "grana"}),
    "green_olives": defineMessage({id: "ingredient.green_olives", defaultMessage: "olive verdi"}),
    "grilled_ham": defineMessage({id: "ingredient.grilled_ham", defaultMessage: "prosciutto cotto alla brace"}),
    "hamburger": defineMessage({id: "ingredient.hamburger", defaultMessage: "hamburger"}),
    "honey_mushrooms": defineMessage({id: "ingredient.honey_mushrooms", defaultMessage: "chiodini"}),
    "ketchup": defineMessage({id: "ingredient.ketchup", defaultMessage: "ketchup"}),
    "lemon_vodka": defineMessage({id: "ingredient.lemon_vodka", defaultMessage: "vodka al limone"}),
    "lettuce": defineMessage({id: "ingredient.lettuce", defaultMessage: "lattuga"}),
    "malted_wheat_flour": defineMessage({id: "ingredient.malted_wheat_flour", defaultMessage: "farina di frumento maltata"}),
    "mayonnaise": defineMessage({id: "ingredient.mayonnaise", defaultMessage: "maionese"}),
    "mixed_mushrooms": defineMessage({id: "ingredient.mixed_mushrooms", defaultMessage: "misto funghi"}),
    "mortadella": defineMessage({id: "ingredient.mortadella", defaultMessage: "mortadella"}),
    "mozzarella": defineMessage({id: "ingredient.mozzarella", defaultMessage: "mozzarella"}),
    "mozzarella_di_bufala": defineMessage({id: "ingredient.mozzarella_di_bufala", defaultMessage: "mozzarella di bufala"}),
    "mushrooms": defineMessage({id: "ingredient.mushrooms", defaultMessage: "funghi"}),
    "mustard": defineMessage({id: "ingredient.mustard", defaultMessage: "senape"}),
    "nuts": defineMessage({id: "ingredient.nuts", defaultMessage: "noci"}),
    "omelette": defineMessage({id: "ingredient.omelette", defaultMessage: "frittatina"}),
    "onion": defineMessage({id: "ingredient.onion", defaultMessage: "cipolla"}),
    "onion_rings": defineMessage({id: "ingredient.onion_rings", defaultMessage: "anelli di cipolla"}),
    "oregano": defineMessage({id: "ingredient.oregano", defaultMessage: "origano"}),
    "peach_vodka": defineMessage({id: "ingredient.peach_vodka", defaultMessage: "vodka alla pesca"}),
    "peas": defineMessage({id: "ingredient.peas", defaultMessage: "piselli"}),
    "peppers": defineMessage({id: "ingredient.peppers", defaultMessage: "peperoni"}),
    "pistachio_pesto": defineMessage({id: "ingredient.pistachio_pesto", defaultMessage: "pesto di pistacchio"}),
    "porcino_mushrooms": defineMessage({id: "ingredient.porcino_mushrooms", defaultMessage: "porcini"}),
    "pulled_pork": defineMessage({id: "ingredient.pulled_pork", defaultMessage: "pulled pork"}),
    "radicchio": defineMessage({id: "ingredient.radicchio", defaultMessage: "radicchio (solo in stagione)"}),
    "red_marinated_treviso_radicchio_igp": defineMessage({id: "ingredient.red_marinated_treviso_radicchio_igp", defaultMessage: "radicchio rosso di TV marinato IGP"}),
    "raw_ham": defineMessage({id: "ingredient.raw_ham", defaultMessage: "prosciutto crudo"}),
    "red_martini": defineMessage({id: "ingredient.red_martini", defaultMessage: "Martini rosso"}),
    "ricotta": defineMessage({id: "ingredient.ricotta", defaultMessage: "ricotta"}),
    "roast_pork": defineMessage({id: "ingredient.roast_pork", defaultMessage: "porchetta"}),
    "rocket_salad": defineMessage({id: "ingredient.rocket_salad", defaultMessage: "rucola"}),
    "salt": defineMessage({id: "ingredient.salt", defaultMessage: "sale"}),
    "sausage": defineMessage({id: "ingredient.sausage", defaultMessage: "salsiccia"}),
    "scamorza": defineMessage({id: "ingredient.scamorza", defaultMessage: "scamorza"}),
    "shrimps": defineMessage({id: "ingredient.shrimps", defaultMessage: "gamberetti"}),
    "sliced_horse": defineMessage({id: "ingredient.sliced_horse", defaultMessage: "sfilacci di cavallo"}),
    "sliced_tomatoes": defineMessage({id: "ingredient.sliced_tomatoes", defaultMessage: "pomodoro a fette"}),
    "smoked_bacon": defineMessage({id: "ingredient.smoked_bacon", defaultMessage: "pancetta affumicata"}),
    "smoked_scamorza": defineMessage({id: "ingredient.smoked_scamorza", defaultMessage: "scamorza affumicata"}),
    "soft_bread": defineMessage({id: "ingredient.soft_bread", defaultMessage: "pane morbido"}),
    "speck": defineMessage({id: "ingredient.speck", defaultMessage: "speck"}),
    "spicy_salami": defineMessage({id: "ingredient.spicy_salami", defaultMessage: "salamino piccante"}),
    "spinach": defineMessage({id: "ingredient.spinach", defaultMessage: "spinaci"}),
    "tabasco": defineMessage({id: "ingredient.tabasco", defaultMessage: "tabasco"}),
    "taggiasca_olives": defineMessage({id: "ingredient.taggiasca_olives", defaultMessage: "olive taggiasche"}),
    "tomato": defineMessage({id: "ingredient.tomato", defaultMessage: "pomodoro"}),
    "tomato_sauce": defineMessage({id: "ingredient.tomato_sauce", defaultMessage: "salsa di pomodoro"}),
    "tropea_onion": defineMessage({id: "ingredient.tropea_onion", defaultMessage: "cipolla di Tropea"}),
    "tuna": defineMessage({id: "ingredient.tuna", defaultMessage: "tonno"}),
    "turkey": defineMessage({id: "ingredient.turkey", defaultMessage: "tacchino"}),
    "vegetables": defineMessage({id: "ingredient.vegetables", defaultMessage: "verdure"}),
    "water": defineMessage({id: "ingredient.water", defaultMessage: "acqua"}),
    "wholemeal_flour": defineMessage({id: "ingredient.wholemeal_flour", defaultMessage: "farina integrale"}),
    "würstel": defineMessage({id: "ingredient.würstel", defaultMessage: "würstel"}),
    "zucchini": defineMessage({id: "ingredient.zucchini", defaultMessage: "zucchine"}),
} as const

export function formatIngredient(intl: IntlShape, ingredient: Ingredient) {
    return intl.formatMessage(INGREDIENTS[ingredient])
}

export function formatIngredients(intl: IntlShape, ingredients: IngredientOrText[]) {
    return intl.formatList(ingredients.map(ingredient => {
        if (typeof ingredient === "string") {
            return formatIngredient(intl, ingredient)
        } else {
            return resolveIntlText(intl, ingredient.text)
        }
    }))
}

export function hamburgerOfWeightIngredient(grams: number) {
    return {
        text: (intl: IntlShape) => {
            return intl.formatMessage({
                id: "ingredient.hamburger_of_weight",
                defaultMessage: "hamburger da {weight, number, ::unit/gram}",
            }, {weight: grams})
        }
    }
}

