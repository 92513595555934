import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {eur} from "../../utils";
import {defineMessage} from "react-intl";


const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "coffee_and_hot_drinks", defaultMessage: "Caffè e bevande"}),
        entries: [
            {
                name: defineMessage({id: "coffee", defaultMessage: "Caffè"}),
                price: eur(130)
            },
            {
                name: defineMessage({id: "decaffeinated_coffee", defaultMessage: "Decaffeinato"}),
                price: eur(130)
            },
            {
                name: defineMessage({id: "coffee_with_liquor", defaultMessage: "Caffè corretto"}),
                price: eur(180)
            },
            {
                name: defineMessage({id: "barley", defaultMessage: "Orzo"}),
                price: eur(130)
            },
            {
                name: defineMessage({id: "cappuccino", defaultMessage: "Cappuccino"}),
                price: eur(190)
            },
            {
                name: defineMessage({id: "shaken_coffee", defaultMessage: "Caffè shakerato"}),
                price: eur(220)
            },
            {
                name: defineMessage({id: "hot_tea", defaultMessage: "Tè caldo"}),
                price: eur(200)
            },
            {
                name: defineMessage({id: "hot_flavored_tea", defaultMessage: "Tè caldo aromatizzato"}),
                description: intl => intl.formatList([
                    intl.formatMessage({id: "tea.peach", defaultMessage: "pesca"}),
                    intl.formatMessage({id: "tea.berries", defaultMessage: "frutti di bosco"}),
                    intl.formatMessage({id: "tea.green", defaultMessage: "verde"}),
                ], {type: "disjunction"}),
                price: eur(240)
            },
        ],
    },
    {
        name: defineMessage({id: "liqueurs", defaultMessage: "Liquori"}),
        entries: [
            {
                name: defineMessage({id: "amaros", defaultMessage: "Amari"}),
                price: eur(350)
            },
            {
                name: defineMessage({id: "grappas", defaultMessage: "Grappe"}),
                price: eur(350)
            },
            {
                name: defineMessage({id: "cognac", defaultMessage: "Cognac"}),
                price: eur(450)
            },
            {
                name: defineMessage({id: "whiskey", defaultMessage: "Whisky"}),
                price: eur(450)
            },
            {
                name: defineMessage({id: "brandy", defaultMessage: "Brandy"}),
                price: eur(450)
            },
            {
                name: "Grappa barricata",
                price: eur(450)
            },
        ]
    },
    {
        name: defineMessage({id: "long_drinks", defaultMessage: "Long drinks"}),
        entries: [
            {
                name: defineMessage({id: "gin_tonic", defaultMessage:"Gin tonic"}),
                price: eur(600)
            },
            {
                name: defineMessage({id: "gin_lemon", defaultMessage:"Gin lemon"}),
                price: eur(600)
            },
            {
                name: "Scivolo",
                ingredients: ["peach_vodka", {text: "Lemonsoda"}],
                price: eur(600)
            },
            {
                name: "Lemon lemon",
                ingredients: ["lemon_vodka", {text: "Lemonsoda"}],
                price: eur(600)
            },
            {
                name: "Bacardi cola",
                price: eur(600)
            },
            {
                name: "Havana 3V cola",
                price: eur(600)
            },
            {
                name: "Havana 7V cola",
                price: eur(600)
            },
        ]
    },
]

export default function Bar() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}