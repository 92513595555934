import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {formatIngredient} from "../../intl/ingredients";
import {eur} from "../../utils";
import {defineMessage} from "react-intl";
import {ingredientWeightFormatter} from "../../intl/intl-utils";


const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "dishes", defaultMessage: "Piatti"}),
        entries: [
            {
                name: "Caprese",
                ingredients: ["sliced_tomatoes", "mozzarella_di_bufala", "oregano"],
                price: eur(900)
            },
            {
                name: "Affettato misto",
                ingredients: ["turkey", "raw_ham", "cooked_ham", "roast_pork", "bresaola"],
                price: eur(900)
            },
            {
                name: "Piatto Tre",
                ingredients: ["mushrooms", "zucchini", "peppers", "eggplant"],
                price: eur(900)
            },
            {
                name: "Piatto Quattro",
                ingredients: ["omelette", "eggplant", "mushrooms", "zucchini"],
                price: eur(900)
            },
            {
                name: "Piatto Cinque",
                ingredients: ["bresaola", "rocket_salad", "grana"],
                price: eur(900)
            },
            {
                name: "Piatto Sei",
                ingredients: ["turkey", "sliced_tomatoes", "zucchini"],
                price: eur(900)
            },
            {
                name: "Piatto Sette",
                ingredients: ["mozzarella_di_bufala", "artichokes", "cherry_tomatoes", "raw_ham"],
                price: eur(1000)
            },
            {
                name: "Piatto Otto",
                ingredients: [{
                    text: intl => {
                        return intl.formatList([
                            ingredientWeightFormatter("hamburger", 130)(intl),
                            formatIngredient(intl, "cutlet")
                        ], {type: "disjunction"})
                    }
                }],
                price: eur(400)
            },
            {
                name: "Piatto Nove",
                ingredients: [
                    "raw_ham",
                    {
                        text: intl => intl.formatList([
                            formatIngredient(intl, "mozzarella_di_bufala"),
                            formatIngredient(intl, "burrata")
                        ], {type: "disjunction"})
                    }
                ],
                price: eur(400)
            },
            {
                name: defineMessage({id: "dish_with___", defaultMessage: "Piatto con..."}),
                ingredients: [
                    {
                        text: intl => {
                            return intl.formatList([
                                formatIngredient(intl, "hamburger"),
                                formatIngredient(intl, "cutlet"),
                                formatIngredient(intl, "würstel"),
                            ], {type: "disjunction"})
                        }
                    },
                    "french_fires",
                    "cocktail_sauce"
                ],
                price: eur(750)
            },
        ],
    },
    {
        name: defineMessage({id: "salads", defaultMessage: "Insalate"}),
        entries: [
            {
                name: defineMessage({id: "make_your_own_salad", defaultMessage: "Componete la vostra insalata"}),
                ingredients: ["shrimps", "carrots", "cucumbers", "onion", "tuna", "corn", "rocket_salad", "omelette", "dark_olives", "green_olives", "mozzarella", "lettuce"],
                price: eur(900)
            }
        ]
    },
    {
        name: intl => intl.formatMessage({
            id: "dishes_with_hamburger_steaks_of_weight",
            defaultMessage: "Piatti con tagliata di hamburger da {weight, number, ::unit/gram}"
        }, {weight: 180}),
        entries: [
            {
                name: "Numero Uno",
                ingredients: ["hamburger", "french_fires"],
                price: eur(950)
            },
            {
                name: "Numero Due",
                ingredients: ["hamburger", "grana", "tropea_onion"],
                price: eur(950)
            },
            {
                name: "Numero Tre",
                ingredients: ["hamburger", "fried_egg", "rocket_salad"],
                price: eur(950)
            },
            {
                name: "Numero Quattro",
                ingredients: ["hamburger", "zucchini", "peppers", "eggplant"],
                price: eur(950)
            },
        ]
    }
]

export default function Piatti() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}