import React from "react";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import MenuCategoryPage from "../MenuCategoryPage";
import Text from "antd/es/typography/Text";
import {EachAddition, SauceAddition} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {defineMessage, FormattedMessage} from "react-intl";

const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "hamburgers", defaultMessage: "Hamburger"}),
        subtitle: <>
            <Text>
                <FormattedMessage
                    id="for_each_dish_you_can_choose_from_colon"
                    defaultMessage="Per ogni panino è possibile scegliere tra:"
                />
            </Text>
            <ul>
                <li>
                    <FormattedMessage
                        id="beef_burger_with_weight"
                        defaultMessage="Hamburger di carne bovina da {weight, number, ::unit/gram}"
                        values={{weight: 130}}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="cutlet"
                        defaultMessage="Cotoletta"
                    />
                </li>
            </ul>
        </>,
        entries: [
            {
                name: "Jerry",
                ingredients: ["tomato", "lettuce", "hamburger", "ketchup"],
                price: eur(800)
            },
            {
                name: "Super",
                ingredients: ["tomato", "lettuce", "onion", "hamburger", "mustard", "ketchup", "omelette"],
                price: eur(800)
            },
            {
                name: "Rustico",
                ingredients: ["tomato", "lettuce", "hamburger", "omelette"],
                price: eur(800)
            },
            {
                name: "Cheeseburger",
                ingredients: ["tomato", "lettuce", "hamburger", "cheese"],
                price: eur(800)
            },
            {
                name: "Speedy",
                ingredients: ["hamburger", "ketchup", "mustard", "french_fires"],
                price: eur(800)
            },
            {
                name: "Gustoso",
                ingredients: ["tomato", "lettuce", "onion", "mustard", "hamburger", "ketchup", "cheese"],
                price: eur(800)
            }
        ]
    },
    {
        name: intl => intl.formatMessage({
            id: "hamburgers_of_weight",
            defaultMessage: "Hamburger da {weight, number, ::unit/gram}",
        }, {weight: 360}),
        subtitle: <>
            <EachAddition price={eur(100)}/>
            <br/>
            <SauceAddition/>
        </>,
        entries: [
            {
                name: "Lucifero",
                ingredients: ["hamburger", "tomato", "chili_pepper", "tabasco", "gorgonzola", "lettuce", "spicy_salami"],
                price: eur(1500)
            },
            {
                name: "Principe",
                ingredients: ["hamburger", "cheese", "lettuce", "onion", "bacon", "porcino_mushrooms"],
                price: eur(1500)
            },
            {
                name: "Cicciotto",
                ingredients: ["hamburger", "rocket_salad", "asiago", "omelette", "peppers"],
                price: eur(1500)
            },
            {
                name: "Principessa",
                ingredients: ["hamburger", "bacon", "smoked_scamorza", "lettuce", "eggplant"],
                price: eur(1500)
            },
            {
                name: "Gordo",
                ingredients: ["hamburger", "mortadella", "pistachio_pesto", "scamorza", "lettuce", "chopped_pistachios"],
                price: eur(1500)
            },
        ]
    },
    {
        name: intl => intl.formatMessage({
            id: "pork_burgers_of_weight",
            defaultMessage: "Hamburger di maiale da {weight, number, ::unit/gram}",
        }, {weight: 150}),
        subtitle: <>
            <EachAddition price={eur(100)}/>
            <br/>
            <SauceAddition/>
        </>,
        entries: [
            {
                name: "Numero Uno",
                ingredients: ["hamburger", "tropea_onion", "cheese", "mustard"],
                price: eur(900)
            },
            {
                name: "Numero Due",
                ingredients: ["hamburger", "porcino_mushrooms", "asiago", "lettuce", "mayonnaise"],
                price: eur(900)
            },
            {
                name: "Numero Tre",
                ingredients: ["hamburger", "peppers", "roast_pork", "barbecue_sauce"],
                price: eur(900)
            },
        ]
    }
]

export default function PaniniEPiadine() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}
