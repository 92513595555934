import React, {HTMLAttributes, ReactNode} from "react";
import css from "./BoxedWarning.module.css";
import Paragraph from "antd/es/typography/Paragraph";

export default function BoxedWarning({icon, children, ...props}: {
    icon: ReactNode
    children: ReactNode
} & HTMLAttributes<HTMLDivElement>) {
    return <div
        {...props}
        className={[css.root, props.className ?? ""].join(" ")}
    >
        {icon}
        <Paragraph className={css.paragraph}>
            {children}
        </Paragraph>
    </div>
}