import React, {useMemo} from "react";
import Title from "antd/es/typography/Title";
import maps from "../images/maps.png"
import {Button, Col, Row} from "antd";
import {FacebookOutlined, GoogleOutlined, InstagramOutlined} from "@ant-design/icons"
import css from "./Contacts.module.css"
import {useTitle} from "../utils";
import {FACEBOOK_LINK, INSTAGRAM_LINK, MAPS_LINK} from "../App";
import PhoneLink from "../components/PhoneLink";
import Img from "../components/Img";
import {defineMessage, FormattedMessage, IntlShape, useIntl} from "react-intl";
import InfoView from "../components/InfoView";

const HOURS = {
    1: null,
    2: [18, 1],
    3: [18, 1],
    4: [18, 1],
    5: [18, 1],
    6: [18, 1],
    7: [18, 1],
}

export default function Contacts() {
    const intl = useIntl()
    useTitle("Contatti")
    const dayOfWeeks = useMemo(() => {
        return [0, 1, 2, 3, 4, 5, 6].map(dow => {
            return intl.formatDate(new Date(`1970-01-${12 + dow}`), {
                weekday: "long",
                timeZone: "UTC"
            })
        })
    }, [intl])

    return <Row>
        <Col xs={0} md={4} lg={6}/>
        <Col xs={24} md={16} lg={12}>
            <Title level={3}>
                <FormattedMessage id="about_us" defaultMessage="Contatti"/>
            </Title>

            <a href={MAPS_LINK}>
                <Img
                    alt=""
                    image={maps}
                    style={{
                        width: "100%",
                        height: "250px",
                        objectFit: "cover"
                    }}
                />
            </a>

            <span translate="no">Via Moglianese 17, 30030, Martellago (Venezia)</span><br/>
            <InfoView
                variant="colon"
                name={defineMessage({id: "phone_number", defaultMessage: "Telefono"})}
                info={<PhoneLink/>}
                nameStyle={{fontWeight: "normal"}}
            />


            <Row style={{marginTop: "24px"}}>
                <Col xs={24} md={12}>
                    <Title level={3}>
                        <FormattedMessage id="opening_hours" defaultMessage="Orari"/>
                    </Title>
                    <table className={css.hoursTable}>
                        <tbody>
                        {Object.entries(HOURS).map(([dow, hours]) => {
                            return <tr key={dow}>
                                <td width="100px">{dayOfWeeks[parseInt(dow) - 1]}</td>
                                <td>
                                    {hours === null ?
                                        <FormattedMessage id="closed" defaultMessage="Chiuso"/> :
                                        formatTimeRange(intl, hours[0], hours[1])
                                    }
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                </Col>
                <Col xs={24} md={0} style={{marginTop: "24px"}}/>
                <Col xs={24} md={12}>
                    <Title level={3}>
                        <FormattedMessage
                            id="socials"
                            defaultMessage="Social"
                        />
                    </Title>
                    <div className={css.socialButtons}>
                        <Button
                            href={INSTAGRAM_LINK}
                            icon={<InstagramOutlined/>}
                            className={css.instagram}
                            children="Instagram"
                        />
                        <Button
                            href={FACEBOOK_LINK}
                            icon={<FacebookOutlined/>}
                            className={css.facebook}
                            children="Facebook"
                        />
                        <Button
                            href={MAPS_LINK}
                            icon={<GoogleOutlined/>}
                            className={css.google}
                            children="Google"
                        />
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
}

function formatHour(intl: IntlShape, hour: number) {
    return intl.formatDate(new Date(0).setUTCHours(hour), {
        hour: "numeric",
        timeZone: "UTC"
    })
}

function formatTimeRange(intl: IntlShape, startHour: number, endHour: number) {
    return `${formatHour(intl, startHour)} - ${formatHour(intl, endHour)}`
}
