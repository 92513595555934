import React from "react";
import {FancyBottledBeer} from "../../datastruct/Beers";
import FancyBottledBeerView from "./FancyBottledBeerView";
import css from "./FancyBottledBeers.module.css"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

export default function FancyBottledBeers(props: {
    beers: FancyBottledBeer[]
}) {
    const breakpoints = useBreakpoint();
    const isMobile = !breakpoints["md"]

    return <div className={[css.root, isMobile ? css.rootMobile : undefined].join(" ")}>
        {props.beers.map((beer, i) => <FancyBottledBeerView key={i} beer={beer}/>)}
    </div>
}