import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {bottleFormatter, pitcherFormatter, volumeFormatter} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {defineMessage} from "react-intl";


const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "aperitifs", defaultMessage: "Aperitivi"}),
        entries: [
            {
                name: "Gingerino",
                price: eur(250)
            },
            {
                name: "Crodino",
                price: eur(250)
            },
            {
                name: "Spritz",
                price: [
                    {
                        description: defineMessage({id: "glass", defaultMessage: "bicchiere"}),
                        price: eur(250)
                    },
                    {
                        description: volumeFormatter(1500),
                        price: eur(2500)
                    }
                ]
            },
            {
                name: "Americano",
                ingredients: [{text: "bitter"}, {text: "Campari"}, "red_martini", {text: "soda"}],
                price: eur(450)
            },
            {
                name: "Negroni",
                ingredients: [{text: "Campari"}, "red_martini", "gin"],
                price: eur(450)
            },
        ],
    },
    {
        name: defineMessage({id: "soft_drinks_on_tap", defaultMessage: "Bibite alla spina"}),
        entries: [
            {
                name: "Pepsi",
                price: [
                    {
                        description: volumeFormatter(300),
                        price: eur(250)
                    },
                    {
                        description: volumeFormatter(400),
                        price: eur(350)
                    },
                    {
                        description: pitcherFormatter(1000),
                        price: eur(800)
                    },
                    {
                        description: pitcherFormatter(1500),
                        price: eur(1200)
                    }
                ]
            },
            {
                name: "Radler",
                price: [
                    {
                        description: volumeFormatter(200),
                        price: eur(350)
                    },
                    {
                        description: volumeFormatter(400),
                        price: eur(500)
                    },
                ]
            },
            {
                name: "Diesel",
                price: [
                    {
                        description: volumeFormatter(200),
                        price: eur(350)
                    },
                    {
                        description: volumeFormatter(400),
                        price: eur(500)
                    },
                ]
            }
        ]
    },
    {
        name: defineMessage({id: "soft_drinks", defaultMessage: "Analcolici"}),
        entries: [
            {
                name: "Red Bull",
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(350)
                    },
                ]
            },
            {
                name: "Fanta",
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: "Sprite",
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: "Lemonsoda",
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: "Coca-Cola",
                description: intl => intl.formatList([
                    intl.formatMessage({id: "coke.normal", defaultMessage: "normale"}),
                    intl.formatMessage({id: "coke.zero", defaultMessage: "zero"})
                ], {type: "disjunction"}),
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: "Chinotto",
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: defineMessage({id: "tonic_water", defaultMessage: "Acqua brillante"}),
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: defineMessage({id: "iced_tea", defaultMessage: "The freddo"}),
                description: intl => intl.formatList([
                    intl.formatMessage({id: "peach", defaultMessage: "pesca"}),
                    intl.formatMessage({id: "lemon", defaultMessage: "limone"}),
                ], {type: "disjunction"}),
                price: [
                    {
                        description: volumeFormatter(330),
                        price: eur(250)
                    },
                ]
            },
            {
                name: defineMessage({id: "mineral_water", defaultMessage: "Acqua minerale"}),
                price: [
                    {
                        description: volumeFormatter(500),
                        price: eur(200)
                    },
                    {
                        description: volumeFormatter(1000),
                        price: eur(300)
                    },
                ]
            },
            {
                name: defineMessage({id: "mint_and_water", defaultMessage: "Acqua e menta"}),
                price: [
                    {
                        description: volumeFormatter(300),
                        price: eur(250)
                    },
                    {
                        description: volumeFormatter(400),
                        price: eur(350)
                    },
                ]
            },
            {
                name: defineMessage({id: "fruit_juices", defaultMessage: "Succhi di frutta"}),
                description: intl => intl.formatList([
                    intl.formatMessage({id: "juice.ace", defaultMessage: "ACE"}),
                    intl.formatMessage({id: "juice.apricot", defaultMessage: "albicocca"}),
                    intl.formatMessage({id: "juice.pineapple", defaultMessage: "ananas"}),
                    intl.formatMessage({id: "juice.pear", defaultMessage: "pera"}),
                    intl.formatMessage({id: "juice.peach", defaultMessage: "pesca"}),
                ], {type: "disjunction"}),
                price: [
                    {
                        description: volumeFormatter(200),
                        price: eur(250)
                    },
                ]
            },
        ]
    },
    {
        name: defineMessage({id: "bottled_wines", defaultMessage: "Vini in bottiglia"}),
        entries: [
            {
                name: "Prosecco",
                price:  [
                    {
                        description: volumeFormatter(250),
                        price: eur(300)
                    },
                    {
                        description: volumeFormatter(500),
                        price: eur(600)
                    },
                    {
                        description: bottleFormatter(750),
                        price: eur(1200)
                    },
                ]
            },
            {
                name: "Cabernet",
                price: [
                    {
                        description: volumeFormatter(250),
                        price: eur(250)
                    },
                    {
                        description: volumeFormatter(500),
                        price: eur(550)
                    },
                    {
                        description: bottleFormatter(750),
                        price: eur(1000)
                    },
                ]
            },
            {
                name: "Chardonnay",
                price: [
                    {
                        description: volumeFormatter(250),
                        price: eur(250)
                    },
                    {
                        description: volumeFormatter(500),
                        price: eur(550)
                    },
                    {
                        description: bottleFormatter(750),
                        price: eur(1000)
                    },
                ]
            },
        ]
    }
]

export default function Bevande() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}