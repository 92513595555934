import css from "./AppFooter.module.css";
import {Col, Row} from "antd";
import PhoneLink from "../components/PhoneLink";
import {FACEBOOK_LINK, INSTAGRAM_LINK} from "../App";
import {FacebookOutlined, InstagramOutlined} from "@ant-design/icons";
import React from "react";
import {FormattedMessage} from "react-intl";

export default function AppFooter() {
    return <Row className={css.footer}>
        <Col xs={24} lg={8} translate="no">
            L'Alternativa S.N.C. Di Villan Gimmy E Perissinotto Barbara<br/>
            <FormattedMessage
                id="vat"
                defaultMessage="P.I. {vat}"
                values={{vat: "03765040278"}}
            />
        </Col>
        <Col xs={24} lg={8} translate="no">
            Via Moglianese 17, Martellago (VE)<br/>
            <FormattedMessage
                id="phone"
                defaultMessage="Telefono: {phone}"
                values={{phone: <PhoneLink/>}}
            />
        </Col>
        <Col xs={24} lg={8}>
            <InstagramOutlined/> <a href={INSTAGRAM_LINK} className={css.instagram}>Instagram</a>
            <br/>
            <FacebookOutlined/> <a href={FACEBOOK_LINK} className={css.facebook}>Facebook</a>
        </Col>
    </Row>
}