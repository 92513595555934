import {defineMessage, FormattedMessage, IntlShape, useIntl} from "react-intl";
import {Price} from "../datastruct/Price";
import PriceView from "../components/price/PriceView";
import Money from "js-money";
import React from "react";
import {formatIngredient, Ingredient} from "./ingredients";

export function formatVolume(intl: IntlShape, milliliters: number) {
    if (milliliters < 1000) {
        return intl.formatNumber(milliliters, {
            style: "unit",
            unit: "milliliter",
            unitDisplay: "short"
        })
    } else {
        return intl.formatNumber(milliliters / 1000, {
            style: "unit",
            unit: "liter",
            unitDisplay: "long"
        })
    }
}

export function volumeFormatter(milliliters: number) {
    return (intl: IntlShape) => formatVolume(intl, milliliters)
}

export function xPiecesFormatter(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_pieces",
        defaultMessage: "{n, number} {n, plural, one {pezzo} other {pezzi}}",
    }, {n})
}

export function pitcherFormatter(milliliters: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "pitcher_of_volume",
        defaultMessage: "caraffa da {volume}"
    }, {volume: formatVolume(intl, milliliters)})
}

export function bottleFormatter(milliliters: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "bottle_of_volume",
        defaultMessage: "bottiglia da {volume}"
    }, {volume: formatVolume(intl, milliliters)})
}

export function ingredientWeightFormatter(ingredient: Ingredient, weight: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "ingredient_of_weight",
        defaultMessage: "{ingredient} da {weight, number, ::unit/gram}"
    }, {
        ingredient: formatIngredient(intl, ingredient),
        weight: weight
    })
}

export function afterCookingIngredient(ingredient: Ingredient) {
    return (intl: IntlShape) => {
        return intl.formatMessage({
            id: "ingredient_x_after_cooking",
            defaultMessage: "{ingredient} (dopo cottura)"
        }, {ingredient: formatIngredient(intl, ingredient)})
    }
}

export function EachAddition({price}: { price: Price }) {
    return <FormattedMessage
        id="each_addition_x"
        defaultMessage="Ogni aggiunta {price}"
        values={{price: <PriceView price={price}/>}}
    />
}

export function EachAdditionOrChange({price}: { price: Price }) {
    return <FormattedMessage
        id="each_addition_or_change_x"
        defaultMessage="Ogni aggiunta o variazione {price}"
        values={{price: <PriceView price={price}/>}}
    />
}

const SAUCE_MAYONNAISE = defineMessage({id: "sauce.mayonnaise", defaultMessage: "maionese"})
const SAUCE_KETCHUP = defineMessage({id: "sauce.ketchup", defaultMessage: "ketchup"})
const SAUCE_MUSTARD = defineMessage({id: "sauce.mustard", defaultMessage: "senape"})
const SAUCE_BARBECUE = defineMessage({id: "sauce.barbecue", defaultMessage: "barbecue"})
const SAUCE_COCKTAIL = defineMessage({id: "sauce.cocktail", defaultMessage: "salsa rosa"})

export function SauceAddition({price}: { price?: Price }) {
    const intl = useIntl()
    return <>
        <FormattedMessage
            id="sauce_addition"
            defaultMessage="Aggiunta salsa {price}"
            values={{price: <PriceView price={price ?? new Money(50, Money.EUR)}/>}}
        />
        <br/>
        <FormattedMessage
            id="available_sauces_x"
            defaultMessage="Salse disponibili: {sauces}"
            values={{
                sauces: intl.formatList([
                    intl.formatMessage(SAUCE_MAYONNAISE),
                    intl.formatMessage(SAUCE_KETCHUP),
                    intl.formatMessage(SAUCE_MUSTARD),
                    intl.formatMessage(SAUCE_BARBECUE),
                    intl.formatMessage(SAUCE_COCKTAIL),
                ])
            }}
        />
    </>
}