import React from "react";
import {MenuSubCategory} from "../../../datastruct/MenuEntries";
import MenuSubCategoryView from "../../../components/MenuSubCategoryView";
import {volumeFormatter} from "../../../intl/intl-utils";
import {eur} from "../../../utils";
import {defineMessage} from "react-intl";

const BEERS: MenuSubCategory = {
    name: defineMessage({id: "bottled_beers", defaultMessage: "Birre in bottiglia"}),
    entries: [
        {
            name: "Heineken",
            ingredients: [],
            price: [{
                description: volumeFormatter(330),
                price: eur(350)
            }]
        },
        {
            name: "Messina",
            price: [{
                description: volumeFormatter(500),
                price: eur(500)
            }]
        },
        {
            name: "Corona",
            price: [{
                description: volumeFormatter(330),
                price: eur(400)
            }]
        },
        {
            name: "Franziskaner",
            price: [{
                description: volumeFormatter(500),
                price: eur(500)
            }]
        },
        {
            name: "Moretti Zero",
            price: [{
                description: volumeFormatter(330),
                price: eur(350)
            }]
        },
        {
            name: "Tennent's",
            price: [{
                description: volumeFormatter(330),
                price: eur(400)
            }]
        },
        {
            name: intl => intl.formatMessage(
                defineMessage({id: "beer_x_unfiltered", defaultMessage: "{beer} non filtrata"}),
                {beer: "Ichnusa"}
            ),
            price: [{
                description: volumeFormatter(500),
                price: eur(500)
            }]
        },
    ]
}

export default function BottledBeers() {
    return <MenuSubCategoryView subCategory={BEERS}/>
}