import React from "react";
import {Menu} from "antd";
import {MAIN_PAGES, SUPPORTED_LANGUAGES, useApp} from "../App";
import {useHistory} from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import {GlobalOutlined} from "@ant-design/icons";
import {createIntl, useIntl} from "react-intl";

export default function AppMenu() {
    const app = useApp()
    const history = useHistory()
    const intl = useIntl()
    const split = history.location.pathname.split("/")
    const selected = split.length >= 2 ? "/" + split[1] : undefined

    return <Menu theme="dark" mode="horizontal" selectedKeys={selected ? [selected] : []}>
        {Object.entries(MAIN_PAGES).map(([path, page]) => {
            return <Menu.Item
                onClick={() => history.push(path)}
                key={path}
                children={page.name}
                style={{textTransform: "uppercase"}}
            />
        })}
        <SubMenu
            icon={<GlobalOutlined/>}
            style={{float: "right", textTransform: "capitalize"}}
            title={intl.formatDisplayName(intl.locale, {
                style: "short",
                type: "language",
                fallback: "code"
            })}
        >
            {SUPPORTED_LANGUAGES.map(lang => {
                const intl = createIntl({locale: lang})
                return <Menu.Item
                    key={lang}
                    onClick={() => app.changeLocale(lang)}
                    style={{textTransform: "capitalize"}}
                >
                    {intl.formatDisplayName(lang, {
                        style: "long",
                        type: "language",
                        fallback: "code"
                    })}
                </Menu.Item>
            })}
        </SubMenu>
    </Menu>
}