import React, {useEffect, useState} from "react";
import css from "./MenuCategoryButton.module.css"
import {MenuCategory} from "../datastruct/MenuEntries";
import {useHistory, useRouteMatch} from "react-router-dom";
import Img from "./Img";
import {useIntlText} from "../intl/IntlText";

export default function MenuCategoryButton({category, variant}: {
    category: MenuCategory,
    variant: "square" | "rectangle"
}) {
    const match = useRouteMatch()
    const history = useHistory()
    const [img, setImg] = useState<ResponsiveImageOutput>()
    const title = useIntlText(category.title)

    useEffect(() => {
        import(`../images/${category.image}?sizes[]=256,sizes[]=512,sizes[]=1024`).then(x => setImg(x.default))
    }, [category.image])

    return <div
        className={[css.root, variant === "square" ? css.rootSquare : css.rootRectangle].join(" ")}
        onClick={() => history.push(`${match.url}/${category.path}`)}
    >
        <Img
            alt={title}
            image={img}
            className={css.img}
            style={category.objectPosition ? {objectPosition: category.objectPosition} : {}}
        />
        <div className={css.overlay}>
            <span className={css.title}>{title}</span>
        </div>
    </div>
}