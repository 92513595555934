import React from "react";
import Title from "antd/es/typography/Title";
import css from "./HomePage.module.css"
import Text from "antd/es/typography/Text";
import {Col, Row} from "antd";
import home from "../images/home.jpg"
import staff from "../images/staff.jpg"
import {Content} from "antd/es/layout/layout";
import logo from "../images/logo.svg"
import FirstLetter from "../components/FirstLetter";
import {useTitle} from "../utils";
import Img from "../components/Img";
import {FormattedList, FormattedMessage, useIntl} from "react-intl";

export default function HomePage() {
    const intl = useIntl()
    const pizzeria = intl.formatMessage({id: "pizzeria", defaultMessage: "Pizzeria"})
    const pub = intl.formatMessage({id: "pub", defaultMessage: "Pub"})
    const birreria = intl.formatMessage({id: "brewery", defaultMessage: "Birreria"})
    const paninoteca = intl.formatMessage({id: "paninoteca", defaultMessage: "Paninoteca"})
    useTitle(intl.formatList([pizzeria, pub, birreria, paninoteca], {style: "short"}))

    return <div className={css.root}>
        <div className={css.welcome}>
            <Img alt="" image={home}/>
            <div className="overlay" data-overlay={true}/>
            <div data-content={true}>
                <Img alt="L'Alternativa" image={logo} className={css.title}/>
                <span className={css.subtitle}>
                    <FormattedList
                        type="conjunction"
                        value={[
                            <FirstLetter value={pizzeria}/>,
                            <FirstLetter value={pub}/>,
                            <FirstLetter value={birreria}/>,
                            <FirstLetter value={paninoteca}/>,
                        ]}
                    />
                </span>
            </div>
        </div>
        <div className={css.info}>
            <Content style={{margin: "auto", padding: "16px"}}>
                <Row justify="center">
                    <Col xs={24} md={16} lg={12}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Title level={2} style={{fontFamily: "Caveat"}}>
                                <FormattedMessage
                                    id="lalternativa_welcome.title"
                                    defaultMessage="L'Alternativa vi dà il benvenuto"
                                />
                            </Title>

                            <Text>
                                <FormattedMessage
                                    id="lalternativa_welcome.paragraph1"
                                    defaultMessage="Nata da Gimmy e Barbara, con la propria atmosfera accogliente e familiare, vi darà ampia scelta tra panini, club sandwich, hamburger e pizze cotte nel forno a legna. E dal momento che una pizza va sempre accompagnata da un'ottima birra, vi aspetta un'ampia selezione sia alla spina che in bottiglia."
                                />
                            </Text>
                            <Img alt="" image={staff}/>
                            <Text>
                                <FormattedMessage
                                    id="lalternativa_welcome.paragraph2"
                                    defaultMessage="Amiamo prenderci cura dei nostri clienti garantendo loro la massima qualità dei prodotti che utilizziamo. Non ci resta che augurarvi una buona serata."
                                />
                            </Text>
                            <Text style={{textAlign: "right", marginTop: 16}}>–&nbsp;<FormattedMessage
                                id="lalternativa_staff"
                                defaultMessage="Staff Alternativa"
                            /></Text>
                        </div>
                    </Col>
                </Row>
            </Content>
        </div>
    </div>
}