import {TapBeer} from "../../datastruct/Beers";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import InfoView from "../InfoView";
import {formatAlcoholicContent} from "../../utils";
import Title from "antd/es/typography/Title";
import css from "./TapBeerView.module.css";
import PricesView from "../price/PricesView";
import {Col, Divider, Row} from "antd";
import React from "react";
import Img from "../Img";
import {defineMessage, useIntl} from "react-intl";
import IntlText from "../../intl/IntlText";

export default function TapBeerView({beer, divider}: {
    beer: TapBeer
    divider: boolean
}) {
    const isMobile = useBreakpoint()["xs"]
    const intl = useIntl()

    const Details = ({variant}: { variant: "colon" | "break" }) => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            {beer.fermentation && <InfoView
                variant={variant}
                name={defineMessage({id: "beer.fermentation", defaultMessage: "Fermentazione"})}
                info={beer.fermentation}
            />}
            <InfoView
                variant={variant}
                name={defineMessage({id: "beer.beer_style", defaultMessage: "Stile birrario"})}
                info={beer.style}
            />
            <InfoView
                variant={variant}
                name={defineMessage({id: "beer.foam", defaultMessage: "Schiuma"})}
                info={beer.foam}
            />
            <InfoView
                variant={variant}
                name={defineMessage({id: "beer.color", defaultMessage: "Colore"})}
                info={beer.shade}
            />
            <InfoView
                variant={variant}
                name={defineMessage({id: "beer.alcoholic_content", defaultMessage: "Grado alcolico"})}
                info={formatAlcoholicContent(intl, beer.alcoholContent)}
            />
        </div>
    }

    const BeerTitle = () => <Title style={{color: beer.color}} level={4}>
        <span translate="no"><IntlText text={beer.name}/></span>
    </Title>

    if (isMobile) {
        return <>
            <BeerTitle/>
            <div className={css.mobileRoot}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Img image={beer.image} alt="" className={css.logo}/>
                    <div style={{flexGrow: 1}}/>
                    <PricesView style={{alignSelf: "flex-end"}} prices={beer.prices}/>
                </div>
                <Details variant="break"/>
            </div>
            <Divider/>
        </>
    } else {
        return <Row>
            <Col sm={8} md={6} lg={4}>
                <Img image={beer.image} alt="" width="100%" className={css.logo}/>
            </Col>
            <Col sm={16} md={18} lg={20}>
                <BeerTitle/>
                <Row>
                    <Col sm={12} md={16} lg={18}>
                        <Details variant="colon"/>
                    </Col>
                    <Col sm={12} md={8} lg={6}>
                        <PricesView style={{float: "right"}} prices={beer.prices}/>
                    </Col>
                </Row>
            </Col>
            {divider && <Col xs={24}>
                <Divider/>
            </Col>}
        </Row>
    }
}