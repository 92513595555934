import {IntlShape} from "react-intl";
import Money from "js-money";

export function formatAlcoholicContent(intl: IntlShape, content: number) {
    return intl.formatMessage({
        id: "alcoholic_volume_format",
        defaultMessage: "{percent, number, ::percent .##} vol"
    }, {
        percent: content
    })
}

export function useTitle(page: string) {
    document.title = `L'Alternativa – ${page}`
}

export function eur(eur: number) {
    return new Money(eur, Money.EUR)
}