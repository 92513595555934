import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {SauceAddition, xPiecesFormatter} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {defineMessage, IntlShape} from "react-intl";

export function xOnionRings(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_onion_rings",
        defaultMessage: "{n, number} {n, plural, one {anello} other {anelli}} di cipolla"
    }, {n})
}

export function xChickenNuggets(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_chicken_nuggets",
        defaultMessage: "{n, number} {n, plural, one {pepita} other {pepite}} di pollo"
    }, {n})
}

export function xAscoliOlives(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_ascoli_olives",
        defaultMessage: "{n, number} {n, plural, one {oliva} other {olive}} ascolane"
    }, {n})
}

export function xMozzarellaBalls(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_mozzarella_balls",
        defaultMessage: "{n, number} {n, plural, one {mozzarellina} other {mozzarelline}}"
    }, {n})
}

export function xPotatoCroquettes(n: number) {
    return (intl: IntlShape) => intl.formatMessage({
        id: "x_potato_croquettes",
        defaultMessage: "{n, number} {n, plural, one {crocchetta} other {crocchette}} di patate"
    }, {n})
}

const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "fried_dishes", defaultMessage: "Fritti"}),
        subtitle: <SauceAddition/>,
        entries: [
            {
                name: defineMessage({id: "french_fires", defaultMessage: "Patatine fritte"}),
                price: eur(500)
            },
            {
                name: defineMessage({id: "battered_vegetables", defaultMessage: "Verdure pastellate"}),
                price: eur(550)
            },
            {
                name: defineMessage({id: "potato_croquettes", defaultMessage: "Crocchette di patate"}),
                price: eur(550)
            },
            {
                name: defineMessage({id: "chicken_nuggets", defaultMessage: "Pepite di pollo"}),
                description: xPiecesFormatter(10),
                price: eur(550)
            },
            {
                name: defineMessage({id: "ascoli_olives", defaultMessage: "Olive ascolane"}),
                description: xPiecesFormatter(10),
                price: eur(550)
            },
            {
                name: defineMessage({id: "mozzarella_balls", defaultMessage: "Mozzarelline"}),
                description: xPiecesFormatter(10),
                price: eur(550)
            },
            {
                name: defineMessage({id: "onion_rings", defaultMessage: "Anelli di cipolla"}),
                description: xPiecesFormatter(10),
                price: eur(550)
            },
            {
                name: defineMessage({id: "fritto_misto", defaultMessage: "Fritto misto"}),
                ingredients: [
                    {text: xOnionRings(2)},
                    {text: xChickenNuggets(2)},
                    {text: xAscoliOlives(2)},
                    {text: xMozzarellaBalls(2)},
                    {text: xPotatoCroquettes(2)},
                    "battered_vegetables",
                ],
                price: eur(600)
            },
            {
                name: defineMessage({id: "fritto_misto_with_fries", defaultMessage: "Fritto misto con patate"}),
                ingredients: [
                    {text: xOnionRings(2)},
                    {text: xChickenNuggets(2)},
                    {text: xAscoliOlives(2)},
                    {text: xPotatoCroquettes(2)},
                    {text: xMozzarellaBalls(2)},
                    "battered_vegetables",
                    "french_fires"
                ],
                price: eur(700)
            },
        ],
    },
    {
        name: defineMessage({id: "bruschetta", defaultMessage: "Bruschette"}),
        entries: [
            {
                name: "Bruschetta Uno",
                ingredients: ["tomato", "garlic", "extra_virgin_olive_oil", "oregano"],
                price: eur(500)
            },
            {
                name: "Bruschetta Due",
                ingredients: ["tomato", "cooked_ham", "brie", "oregano"],
                price: eur(500)
            },
            {
                name: "Bruschetta Tre",
                ingredients: ["tomato", "mozzarella", "oregano"],
                price: eur(500)
            },
            {
                name: "Bruschetta Quattro",
                ingredients: ["tomato", "spicy_salami"],
                price: eur(500)
            },
        ]
    }
]

export default function Antipasti() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}