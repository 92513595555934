import React from "react";
import {MenuEntry} from "../datastruct/MenuEntries";
import ItemRow from "./ItemRow";
import {useIntl} from "react-intl";
import {formatIngredients} from "../intl/ingredients";

export default function MenuEntryView(props: {
    entry: MenuEntry
    divider?: boolean
}) {
    const intl = useIntl()
    let description
    if (props.entry.ingredients) {
        description = formatIngredients(intl, props.entry.ingredients)
    } else {
        description = props.entry.description
    }

    return <ItemRow
        title={props.entry.name}
        description={description}
        price={props.entry.price}
        divider={props.divider}
    />
}
