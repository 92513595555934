import heinekenLogo from "../../../images/heineken.svg";
import messinaCristalliDiSaleLogo from "../../../images/messina-cristalli-di-sale.png";
import morettiLaRossaLogo from "../../../images/moretti-larossa.png";
import watneysScotchBlueBeer from "../../../images/watneys-scotch-blue-beer.png";
import React from "react";
import CategoryView from "../../../components/CategoryView";
import {TapBeer} from "../../../datastruct/Beers";
import TapBeerView from "../../../components/beers/TapBeerView";
import {defineMessage} from "react-intl";
import {pitcherFormatter, volumeFormatter} from "../../../intl/intl-utils";
import {eur} from "../../../utils";

const FOAM_FINE = defineMessage({id: "beer.foam.fine", defaultMessage: "fine"})
const FOAM_ADERENTE = defineMessage({id: "beer.foam.lace", defaultMessage: "aderente"}) // https://beerandbrewing.com/dictionary/64kjt74vxe
const FOAM_COMPATTA = defineMessage({id: "beer.foam.compact", defaultMessage: "compatta"})
const FOAM_CREMOSA = defineMessage({id: "beer.foam.creamy", defaultMessage: "cremosa"})
const FOAM_PERSISTENTE = defineMessage({id: "beer.foam.persistent", defaultMessage: "persistente"})

const BEERS: TapBeer[] = [
    {
        image: heinekenLogo as unknown as ResponsiveImageOutput,
        name: "Heineken",
        color: "#27824D",
        fermentation: defineMessage({id: "beer.fermentation.low", defaultMessage: "bassa"}),
        style: defineMessage({id: "beer.style.lager_premium", defaultMessage: "Lager Premium"}),
        foam: intl => intl.formatList([FOAM_FINE, FOAM_ADERENTE, FOAM_COMPATTA].map(foam => intl.formatMessage(foam))),
        shade: defineMessage({id: "beer.shade.blonde", defaultMessage: "chiara"}),
        alcoholContent: .05,
        prices: [
            {
                description: volumeFormatter(200),
                price: eur(260)
            },
            {
                description: volumeFormatter(400),
                price: eur(500)
            },
            {
                description: pitcherFormatter(1000),
                price: eur(1100)
            },
            {
                description: pitcherFormatter(1500),
                price: eur(1600)
            }
        ]
    },
    {
        image: messinaCristalliDiSaleLogo as unknown as ResponsiveImageOutput,
        name: "Birra Messina - Cristalli di Sale",
        color: "#033f85",
        style: defineMessage({id: "beer.style.lager", defaultMessage: "Lager"}),
        foam: intl => intl.formatList([FOAM_COMPATTA, FOAM_PERSISTENTE].map(foam => intl.formatMessage(foam))),
        shade: defineMessage({id: "beer.shade.gold_and_opalescent", defaultMessage: "dorato e opalescente"}),
        alcoholContent: .05,
        prices: [
            {
                description: volumeFormatter(200),
                price: eur(300)
            },
            {
                description: volumeFormatter(400),
                price: eur(600)
            },
            {
                description: pitcherFormatter(100),
                price: eur(1300)
            },
            {
                description: pitcherFormatter(1500),
                price: eur(1800)
            }
        ]
    },
    {
        image: watneysScotchBlueBeer as unknown as ResponsiveImageOutput,
        name: "Watneys Scotch - Blue Beer",
        color: "#127267",
        fermentation: defineMessage({id: "beer.fermentation.high", defaultMessage: "alta"}),
        style: defineMessage({id: "beer.style.belgian_ale", defaultMessage: "Birra belga"}),
        foam: intl => intl.formatMessage(FOAM_COMPATTA),
        shade: defineMessage({id: "beer.shade.red", defaultMessage: "rossa"}),
        alcoholContent: .08,
        prices: [
            {
                description: volumeFormatter(200),
                price: eur(300)
            },
            {
                description: volumeFormatter(400),
                price: eur(600)
            },
            {
                description: pitcherFormatter(100),
                price: eur(1300)
            },
            {
                description: pitcherFormatter(1500),
                price: eur(1800)
            }
        ]
    },
    {
        image: morettiLaRossaLogo as unknown as ResponsiveImageOutput,
        name: "Birra Moretti - La Rossa",
        color: "#971936",
        fermentation: defineMessage({id: "beer.fermentation.low", defaultMessage: "bassa"}),
        style: defineMessage({id: "beer.style.doppel_bock", defaultMessage: "Doppel Bock"}),
        foam: intl => intl.formatList([FOAM_FINE, FOAM_ADERENTE, FOAM_CREMOSA].map(foam => intl.formatMessage(foam))),
        shade: defineMessage({
            id: "beer.shade.amber_with_ruby_reflexes",
            defaultMessage: "ambrato con riflessi rubini"
        }),
        alcoholContent: .072,
        prices: [
            {
                description: volumeFormatter(200),
                price: eur(280)
            },
            {
                description: volumeFormatter(400),
                price: eur(530)
            },
            {
                description: pitcherFormatter(1000),
                price: eur(1200)
            },
            {
                description: pitcherFormatter(1500),
                price: eur(1700)
            }
        ]
    },
]

export default function DraftBeers() {
    return <CategoryView name={defineMessage({id: "draft_beers", defaultMessage: "Birre alla spina"})}>
        {BEERS.map((beer, i) => <TapBeerView key={i} beer={beer} divider={i < BEERS.length - 1}/>)}
    </CategoryView>
}