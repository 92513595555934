import React, {ImgHTMLAttributes} from "react";

type ImgProps = {
    image?: ResponsiveImageOutput | string
} & Omit<ImgHTMLAttributes<HTMLImageElement>, "img" | "imgSet">

export default function Img({image, ...props}: ImgProps) {
    // eslint-disable-next-line
    return <img
        src={typeof image === "string" ? image : image?.src}
        srcSet={typeof image === "string" ? undefined : image?.srcSet}
        {...props}
    />
}