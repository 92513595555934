import React, {PropsWithChildren} from "react";
import {Content, Footer, Header} from "antd/es/layout/layout";
import {Layout} from "antd";
import AppMenu from "./AppMenu";
import css from "./AppPage.module.css";
import AppFooter from "./AppFooter";

export function AppPage(props: PropsWithChildren<{
    noContent?: boolean
}>) {
    return <Layout className={css.root}>
        <Header style={{padding: 0}}>
            <AppMenu/>
        </Header>
        {props.noContent && props.children}
        {!props.noContent &&
        <Content style={{margin: "16px", minHeight: "calc(100vh - 64px - 32px)"}} children={props.children}/>}
        <Footer>
            <AppFooter />
        </Footer>
    </Layout>
}