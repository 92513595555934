import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {BreadType, MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import CategoryView from "../../components/CategoryView";
import ItemRow from "../../components/ItemRow";
import {defineMessage, FormattedMessage, useIntl} from "react-intl";
import PriceView from "../../components/price/PriceView";
import {EachAddition, SauceAddition} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {formatIngredients} from "../../intl/ingredients";


const BREAD_TYPES: BreadType[] = [
    {
        name: defineMessage({id: "bread.white", defaultMessage: "Pane bianco"}),
        ingredients: ["common_wheat_type_0", "water", "edible_lard", "malted_wheat_flour", "dextrose_emulsifier", "brewers_yeast", "salt"]
    },
    {
        name: defineMessage({id: "bread.black", defaultMessage: "Pane nero"}),
        ingredients: ["common_wheat_type_0", "water", "edible_lard", "malted_wheat_flour", "dextrose_emulsifier", "brewers_yeast", "caramel", "salt"]
    },
    {
        name: defineMessage({id: "bread.whole_wheat", defaultMessage: "Pane integrale"}),
        ingredients: ["common_wheat_type_0", "wholemeal_flour", "water", "edible_lard", "malted_wheat_flour", "dextrose_emulsifier", "brewers_yeast", "salt"]
    },
]

const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "club_sandwiches", defaultMessage: "Club sandwich"}),
        subtitle: <>
            <FormattedMessage
                id="mini_version_price"
                defaultMessage="Versione mini {price}"
                values={{price: <PriceView price={eur(700)}/>}}
            />
            <br/>
            <EachAddition price={eur(100)}/>
        </>,
        entries: [
            {
                name: "Club classico",
                ingredients: ["tomato", "cheese", "lettuce", "omelette", "cooked_ham", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club alla pancetta",
                ingredients: ["tomato", "cheese", "lettuce", "omelette", "bacon", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club alle verdure",
                ingredients: ["tomato", "eggplant", "zucchini", "cheese", "peppers", "asparagus", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club alla porchetta",
                ingredients: ["tomato", "cheese", "peppers", "omelette", "roast_pork", "tabasco", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club allo speck",
                ingredients: ["tomato", "cheese", "speck", "omelette", "mushrooms", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club alla bresaola",
                ingredients: ["tomato", "bresaola", "brie", "rocket_salad", "omelette", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club al prosciutto crudo",
                ingredients: ["tomato", "raw_ham", "zucchini", "cheese", "omelette", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club al tacchino",
                ingredients: ["tomato", "turkey", "cheese", "peppers", "omelette", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club al cotto alla brace",
                ingredients: ["tomato", "grilled_ham", "rocket_salad", "brie", "eggplant", "omelette", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club al tonno",
                ingredients: ["tomato", "omelette", "tuna", "lettuce", "cheese", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club ai gamberetti",
                ingredients: ["tomato", "omelette", "shrimps", "lettuce", "cheese", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club sfilaccino",
                ingredients: ["tomato", "sliced_horse", "asiago", "porcino_mushrooms", "lettuce", "omelette", "cocktail_sauce"],
                price: eur(900)
            },
            {
                name: "Club mortadella",
                ingredients: ["tomato", "omelette", "mortadella", "cheese", "pistachio_pesto", "peppers"],
                price: eur(900)
            },
            {
                name: "Club radicchio",
                ingredients: ["red_marinated_treviso_radicchio_igp", "tomato", "eggs", "bacon", "gorgonzola"],
                price: eur(900)
            },
        ],
    },
    {
        name: defineMessage({id: "toasts", defaultMessage: "Toast"}),
        subtitle: <>
            <EachAddition price={eur(100)}/>
            <br/>
            <SauceAddition/>
        </>,
        entries: [
            {
                name: "Toast Uno",
                ingredients: ["cooked_ham", "cheese"],
                price: eur(500)
            },
            {
                name: "Toast Due",
                ingredients: ["bresaola", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Tre",
                ingredients: ["roast_pork", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Quattro",
                ingredients: ["raw_ham", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Cinque",
                ingredients: ["grilled_ham", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Sei",
                ingredients: ["bacon", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Sette",
                ingredients: ["vegetables", "cheese"],
                price: eur(600)
            },
            {
                name: "Toast Otto",
                ingredients: ["turkey", "cheese"],
                price: eur(600)
            },
        ]
    }
]

export default function Sandwich() {
    const intl = useIntl()
    return <MenuCategoryPage>
        <CategoryView name={defineMessage({id: "choose_bread", defaultMessage: "Scegli il pane!"})}>
            {BREAD_TYPES.map((b, i) => {
                return <ItemRow
                    key={i}
                    title={b.name}
                    description={b.ingredients ? formatIngredients(intl, b.ingredients) : undefined}
                />
            })}
        </CategoryView>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}