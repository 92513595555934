import MenuCategoryPage from "../MenuCategoryPage";
import React from "react";
import DraftBeers from "./beers/DraftBeers";
import BottledBeers from "./beers/BottledBeers";
import ReserveBeers from "./beers/ReserveBeers";

export default function Birre() {
    return <MenuCategoryPage>
        <DraftBeers />
        <BottledBeers />
        <ReserveBeers />
    </MenuCategoryPage>
}