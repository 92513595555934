import React, {CSSProperties, ReactElement} from "react";
import IntlText, {IntlTextType} from "../intl/IntlText";
import {FormattedMessage} from "react-intl";

export default function InfoView(props: {
    name: IntlTextType|ReactElement,
    info: IntlTextType|ReactElement,
    variant: "colon" | "break",
    style?: CSSProperties
    nameStyle?: CSSProperties
}) {
    const name = <span style={{fontWeight: "bold", ...props.nameStyle}}><IntlText text={props.name}/></span>
    const info = <IntlText text={props.info}/>

    if (props.variant === "colon") {
        return <span style={props.style}>
            <FormattedMessage
                id="colonized_value"
                defaultMessage="{name}: {info}"
                values={{name, info}}
            />
        </span>
    } else {
        return <span style={props.style}>
            {name}
            <br/>
            {info}
        </span>
    }
}