import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {eur} from "../../utils";
import {defineMessage} from "react-intl";


const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "desserts", defaultMessage: "Dolci"}),
        entries: [
            {
                name: defineMessage({id: "sorbet", defaultMessage: "Sorbetto"}),
                description: intl => intl.formatList([
                    intl.formatMessage({id: "lemon", defaultMessage: "limone"}),
                    intl.formatMessage({id: "licorice", defaultMessage: "liquirizia"}),
                    intl.formatMessage({id: "other_flavors", defaultMessage: "altri gusti"}),
                ], {type: "disjunction"}), price: eur(300)
            },
            {
                name: "Panna cotta",
                price: eur(450)
            },
            {
                name: "Tiramisù",
                price: eur(450)
            },
            {
                name: defineMessage({id: "black_sweet_salami", defaultMessage: "Salame dolce nero"}),
                price: eur(450)
            },
            {
                name: defineMessage({id: "white_sweet_salami", defaultMessage: "Salame dolce bianco"}),
                price: eur(450)
            },
            {
                name: "Tartufo",
                description: intl => intl.formatList([
                    intl.formatMessage({id: "tartufo.black", defaultMessage: "nero"}),
                    intl.formatMessage({id: "tartufo.white", defaultMessage: "bianco"}),
                ], {type: "disjunction"}),
                price: eur(500)
            },
            {
                name: defineMessage({id: "nutella_wrap", defaultMessage: "Piadina con nutella"}),
                price: eur(500)
            },
            {
                name: defineMessage({id: "meringue_cake", defaultMessage: "Meringata"}),
                price: eur(500)
            },
            {
                name: defineMessage({id: "drowned_ice_cream", defaultMessage: "Affogato"}),
                price: eur(600)
            },
            {
                name: defineMessage({id: "cake_service", defaultMessage: "Servizio torta"}),
                price: eur(2000)
            },
        ],
    },
]

export default function Dolci() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 24
        }}>
            <h3>...e molti altri</h3>
        </div>
    </MenuCategoryPage>
}