import React from "react";
import {MenuSubCategory} from "../datastruct/MenuEntries";
import MenuSubCategoryView from "./MenuSubCategoryView";

export default function MenuEntriesView(props: { subCategories: MenuSubCategory[] }) {
    return <>
        {props.subCategories.map((sc, i) => {
            return <React.Fragment key={i}>
                <MenuSubCategoryView subCategory={sc}/>
            </React.Fragment>
        })}
    </>
}