import React from "react";
import CategoryView from "../../components/CategoryView";
import ItemRow from "../../components/ItemRow";
import {BreadType, MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import MenuCategoryPage from "../MenuCategoryPage";
import {EachAddition, SauceAddition} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {defineMessage, FormattedMessage, useIntl} from "react-intl";
import {formatIngredients, hamburgerOfWeightIngredient} from "../../intl/ingredients";

const BREAD_TYPES: BreadType[] = [
    {
        name: defineMessage({id: "crispy_tuscan_bread", defaultMessage: "Pane toscano croccante"}),
        ingredients: ['common_wheat_type_0', "water", "malted_wheat_flour", "brewers_yeast", "salt"]
    },
    {
        name: defineMessage({id: "soft_bread_baked_in_wood_oven", defaultMessage: "Pane morbido cotto in forno a legna"}),
        ingredients: ['common_wheat_type_0', "water", "extra_virgin_olive_oil", "salt"]
    },
    {
        name: defineMessage({id: "wrap", defaultMessage: "Piadina"})
    },
]

const SUBTITLE = <>
    <EachAddition price={eur(100)}/>
    <br/>
    <SauceAddition/>
</>
const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "bacon", defaultMessage: "Pancetta"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Pancetta Uno",
                ingredients: ["bacon", "zucchini", "eggplant", "peppers", "cheese"],
                price: eur(800)
            },
            {
                name: "Pancetta Due",
                ingredients: ["bacon", "smoked_scamorza", "mushrooms"],
                price: eur(800)
            },
            {
                name: "Pancetta Tre",
                ingredients: ["bacon", "lettuce", "tomato", "cheese"],
                price: eur(800)
            },
            {
                name: "Pancetta Quattro",
                ingredients: ["bacon", "peppers", "taggiasca_olives", "cheese"],
                price: eur(800)
            },
            {
                name: "Pancetta Cinque",
                ingredients: ["bacon", "gorgonzola", "onion", "dried_tomatoes"],
                price: eur(800)
            },
            {
                name: "Pancetta Sei",
                ingredients: ["bacon", "omelette", "cheese", "onion"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "raw_ham", defaultMessage: "Prosciutto crudo"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Prosciutto crudo Uno",
                ingredients: ["raw_ham", "tomato", "mozzarella", "lettuce"],
                price: eur(800)
            },
            {
                name: "Prosciutto crudo Due",
                ingredients: ["raw_ham", "mozzarella"],
                price: eur(800)
            },
            {
                name: "Prosciutto crudo Tre",
                ingredients: ["raw_ham", "asiago", "artichokes", "rocket_salad"],
                price: eur(800)
            },
            {
                name: "Prosciutto crudo Quattro",
                ingredients: ["raw_ham", "burrata"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "cooked_ham", defaultMessage: "Prosciutto cotto"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Prosciutto cotto Uno",
                ingredients: ["cooked_ham", "tomato", "mozzarella", "lettuce"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto Due",
                ingredients: ["cooked_ham", "cheese", "eggplant", "peppers", "zucchini"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto Tre",
                ingredients: ["cooked_ham", "mushrooms", "mozzarella"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto Quattro",
                ingredients: ["cooked_ham", "dried_tomatoes", "cheese", "taggiasca_olives"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "speck", defaultMessage: "Speck"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Speck Uno",
                ingredients: ["speck", "mozzarella", "mushrooms"],
                price: eur(800)
            },
            {
                name: "Speck Due",
                ingredients: ["speck", "mozzarella", "tomato", "lettuce"],
                price: eur(800)
            },
            {
                name: "Speck Tre",
                ingredients: ["speck", "brie", "tomato", "lettuce"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "bresaola", defaultMessage: "Bresaola"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Bresaola Uno",
                ingredients: ["bresaola", "brie", "tomato", "rocket_salad"],
                price: eur(800)
            },
            {
                name: "Bresaola Due",
                ingredients: ["bresaola", "cheese", "artichokes"],
                price: eur(800)
            },
            {
                name: "Bresaola Tre",
                ingredients: ["bresaola", "grana", "rocket_salad"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "tuna", defaultMessage: "Tonno"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Tonno Uno",
                ingredients: ["tuna", "mayonnaise", "tomato", "lettuce"],
                price: eur(800)
            },
            {
                name: "Tonno Due",
                ingredients: ["tuna", "mayonnaise", "zucchini", "eggplant"],
                price: eur(800)
            },
            {
                name: "Tonno Tre",
                ingredients: ["tuna", "mayonnaise", "green_olives"],
                price: eur(800)
            },
            {
                name: "Tonno Quattro",
                ingredients: ["tuna", "tomato", "rocket_salad", "mozzarella_di_bufala", "taggiasca_olives"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "roast_pork", defaultMessage: "Porchetta"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Porchetta Uno",
                ingredients: ["roast_pork", "peppers", "eggplant", "würstel", "tabasco"],
                price: eur(800)
            },
            {
                name: "Porchetta Due",
                ingredients: ["roast_pork", "würstel", "mustard"],
                price: eur(800)
            },
            {
                name: "Porchetta Tre",
                ingredients: ["roast_pork", "lettuce", "tomato", "mayonnaise"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "sausage", defaultMessage: "Salsiccia"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Salsiccia Uno",
                ingredients: ["sausage", "cheese", "mushrooms", "mayonnaise"],
                price: eur(800)
            },
            {
                name: "Salsiccia Due",
                ingredients: ["sausage", "onion", "peppers", "mustard"],
                price: eur(800)
            },
            {
                name: "Salsiccia Tre",
                ingredients: ["sausage", "cheese", "peppers", "tabasco"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "turkey", defaultMessage: "Tacchino"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Tacchino Uno",
                ingredients: ["turkey", "mozzarella", "tomato", "lettuce"],
                price: eur(800)
            },
            {
                name: "Tacchino Due",
                ingredients: ["turkey", "cheese", "eggplant", "zucchini", "peppers"],
                price: eur(800)
            },
            {
                name: "Tacchino Tre",
                ingredients: ["turkey", "cheese", "tomato", "rocket_salad"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "grilled_ham", defaultMessage: "Prosciutto cotto alla brace"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Prosciutto cotto alla brace Uno",
                ingredients: ["grilled_ham", "mushrooms", "mozzarella", "lettuce"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto alla brace Due",
                ingredients: ["grilled_ham", "cheese", "zucchini", "peppers", "eggplant"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto alla brace Tre",
                ingredients: ["grilled_ham", "brie", "artichokes", "rocket_salad"],
                price: eur(800)
            },
            {
                name: "Prosciutto cotto alla brace Quattro",
                ingredients: ["grilled_ham", "burrata", "pistachio_pesto"],
                price: eur(800)
            },
        ]
    },
    {
        name: "Mortadella",
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Mortadella Uno",
                ingredients: ["mortadella", "pistachio_pesto", "burrata"],
                price: eur(900)
            },
            {
                name: "Mortadella Due",
                ingredients: ["mortadella", "lettuce", "tomato", "mozzarella"],
                price: eur(900)
            },
            {
                name: "Mortadella Tre",
                ingredients: ["mortadella", "zucchini", "burrata"],
                price: eur(900)
            },
            {
                name: "Mortadella Quattro",
                ingredients: ["mortadella", "pistachio_pesto", "burrata", "dried_tomatoes"],
                price: eur(900)
            },
        ]
    },
    {
        name: "Radicchio",
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Radicchio Uno",
                ingredients: ["red_marinated_treviso_radicchio_igp", "roast_pork", "asiago"],
                price: eur(900)
            },
            {
                name: "Radicchio Due",
                ingredients: ["red_marinated_treviso_radicchio_igp", "mortadella", "asiago"],
                price: eur(900)
            },
            {
                name: "Radicchio Tre",
                ingredients: ["red_marinated_treviso_radicchio_igp", "sausage", "gorgonzola"],
                price: eur(900)
            },
            {
                name: "Radicchio Quattro",
                ingredients: [
                    "red_marinated_treviso_radicchio_igp",
                    hamburgerOfWeightIngredient(130),
                    "asiago"
                ],
                price: eur(900)
            },
            {
                name: "Radicchio Cinque",
                ingredients: ["red_marinated_treviso_radicchio_igp", "bacon", "gorgonzola"],
                price: eur(900)
            },
        ]
    },
    {
        name: "Light",
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Light Uno",
                ingredients: ["zucchini", "eggplant", "peppers", "cheese"],
                price: eur(800)
            },
            {
                name: "Light Due",
                ingredients: ["mozzarella", "tomato", "lettuce", "oregano"],
                price: eur(800)
            },
            {
                name: "Light Tre",
                ingredients: ["bresaola", "asiago", "tomato", "rocket_salad"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "alternative_paninos", defaultMessage: "Panini alternativi"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Panin onto",
                ingredients: ["sausage", "peppers", "gorgonzola", "bacon", "tropea_onion"],
                price: eur(900)
            },
            {
                name: "Panin bon",
                ingredients: ["spicy_salami", "taggiasca_olives", "sausage", "tropea_onion", "brie", "lettuce"],
                price: eur(900)
            },
        ],
    },
    {
        name: defineMessage({id: "pulled_pork", defaultMessage: "Pulled pork"}),
        subtitle: <>
            <FormattedMessage
                id="served_with_french_fires"
                defaultMessage="Servito con patatine fritte"
            /><br/>
            <EachAddition price={eur(100)}/><br/>
            <SauceAddition price={eur(50)}/>
        </>,
        entries: [
            {
                name: "Pulled pork",
                ingredients: ["soft_bread", "pulled_pork", "tropea_onion", "lettuce", "barbecue_sauce"],
                price: [
                    {
                        description: {id: "standard", defaultMessage: "normale"},
                        price: eur(1500)
                    },
                    {
                        description: {id: "baby", defaultMessage: "baby"},
                        price: eur(1000)
                    }
                ]
            },
        ]
    },
    {
        name: defineMessage({id: "hot_dog", defaultMessage: "Hot dog"}),
        entries: [
            {
                name: "Hot dog",
                ingredients: ["soft_bread", "würstel", "mustard", "ketchup"],
                price: eur(800)
            },
        ]
    },
]

export default function PaniniEPiadine() {
    const intl = useIntl()
    return <MenuCategoryPage>
        <CategoryView name={defineMessage({id: "choose_bread", defaultMessage: "Scegli il pane!"})}>
            {BREAD_TYPES.map((b, i) => {
                return <ItemRow
                    key={i}
                    title={b.name}
                    description={b.ingredients ? formatIngredients(intl, b.ingredients) : undefined}
                />
            })}
        </CategoryView>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}
