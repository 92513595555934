import React from "react";
import MenuCategoryPage from "../MenuCategoryPage";
import {MenuSubCategory} from "../../datastruct/MenuEntries";
import MenuEntriesView from "../../components/MenuEntiriesView";
import {afterCookingIngredient, EachAddition, EachAdditionOrChange} from "../../intl/intl-utils";
import {eur} from "../../utils";
import {defineMessage, FormattedMessage} from "react-intl";
import {formatIngredient} from "../../intl/ingredients";
import PriceView from "../../components/price/PriceView";

const SUBTITLE = <>
    <EachAdditionOrChange price={eur(150)}/>
    <br/>
    <FormattedMessage
        id="pizza_battuta"
        defaultMessage="Pizza battuta {price}"
        values={{price: <PriceView price={eur(150)}/>}}
    />
</>

const ITEMS: MenuSubCategory[] = [
    {
        name: defineMessage({id: "classic_pizzas", defaultMessage: "Pizze classiche"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Marinara",
                ingredients: ["tomato", "garlic", "oregano"],
                price: eur(450)
            },
            {
                name: "Margherita",
                ingredients: ["tomato", "mozzarella"],
                price: eur(500)
            },
            {
                name: "Diavola",
                ingredients: ["tomato", "mozzarella", "spicy_salami"],
                price: eur(650)
            },
            {
                name: "Pugliese",
                ingredients: ["tomato", "mozzarella", "onion"],
                price: eur(650)
            },
            {
                name: "Romana",
                ingredients: ["tomato", "mozzarella", "anchovies"],
                price: eur(650)
            },
            {
                name: "Greca",
                ingredients: ["tomato", "mozzarella", "dark_olives"],
                price: eur(650)
            },
            {
                name: "Gorgonzola",
                ingredients: ["tomato", "mozzarella", "gorgonzola"],
                price: eur(650)
            },
            {
                name: "Brie",
                ingredients: ["tomato", "mozzarella", "brie"],
                price: eur(650)
            },
            {
                name: "Tonno",
                ingredients: ["tomato", "mozzarella", "tuna"],
                price: eur(650)
            },
            {
                name: "Salsiccia",
                ingredients: ["tomato", "mozzarella", "sausage"],
                price: eur(650)
            },
            {
                name: "Viennese",
                ingredients: ["tomato", "mozzarella", "würstel"],
                price: eur(650)
            },
            {
                name: "Ciliegino",
                ingredients: ["tomato", "mozzarella", "cherry_tomatoes"],
                price: eur(700)
            },
            {
                name: "Patatosa",
                ingredients: ["tomato", "mozzarella", "french_fires"],
                price: eur(700)
            },
            {
                name: "Tropea",
                ingredients: ["tomato", "mozzarella", "tropea_onion"],
                price: eur(700)
            },
            {
                name: "Tonno e cipolla",
                ingredients: ["tomato", "mozzarella", "tuna", "onion"],
                price: eur(750)
            },
            {
                name: "Prosciutto e funghi",
                ingredients: ["tomato", "mozzarella", "cooked_ham", "mushrooms"],
                price: eur(750)
            },
            {
                name: "Inglesina",
                ingredients: ["tomato", "mozzarella", "raw_ham"],
                price: eur(700)
            },
            {
                name: "Porchetta",
                ingredients: ["tomato", "mozzarella", "roast_pork"],
                price: eur(700)
            },
            {
                name: "Chiodini",
                ingredients: ["tomato", "mozzarella", "honey_mushrooms"],
                price: eur(700)
            },
            {
                name: "Tirolese",
                ingredients: ["tomato", "mozzarella", "speck"],
                price: eur(700)
            },
            {
                name: "Gamberetti",
                ingredients: ["tomato", "mozzarella", "shrimps"],
                price: eur(700)
            },
            {
                name: "Pancetta",
                ingredients: ["tomato", "mozzarella", "smoked_bacon"],
                price: eur(700)
            },
            {
                name: "Radicchio",
                ingredients: ["tomato", "mozzarella", "radicchio"],
                price: eur(700)
            },
            {
                name: "Bufala",
                ingredients: ["tomato", "mozzarella", "mozzarella_di_bufala"],
                price: eur(750)
            },
            {
                name: "4 stagioni",
                ingredients: ["tomato", "mozzarella", "artichokes", "mushrooms", "cooked_ham", "spicy_salami"],
                price: eur(800)
            },
            {
                name: "4 formaggi",
                ingredients: ["tomato", "mozzarella", "gorgonzola", "brie", "scamorza"],
                price: eur(800)
            },
            {
                name: "Capricciosa",
                ingredients: ["tomato", "mozzarella", "artichokes", "cooked_ham", "mushrooms"],
                price: eur(800)
            },
            {
                name: "Verdure",
                ingredients: ["tomato", "mozzarella", "peppers", "zucchini", "eggplant"],
                price: eur(800)
            },
            {
                name: "Siciliana",
                ingredients: ["tomato", "mozzarella", "capers", "anchovies", "dark_olives"],
                price: eur(800)
            },
            {
                name: "Napoletana",
                ingredients: ["tomato", "mozzarella", "capers", "anchovies"],
                price: eur(800)
            },
            {
                name: "Parmigiana",
                ingredients: ["tomato", "mozzarella", "eggplant", "grana"],
                price: eur(800)
            },
            {
                name: "Zingara",
                ingredients: ["tomato", "mozzarella", "peppers", "mushrooms", "capers", "dark_olives"],
                price: eur(800)
            }
        ]
    },
    {
        name: defineMessage({id: "special_pizzas", defaultMessage: "Pizze speciali"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Valtellina",
                ingredients: ["tomato", "mozzarella", "rocket_salad", "grana", "bresaola"],
                price: eur(900)
            },
            {
                name: "Primavera",
                ingredients: ["tomato", "mozzarella", "zucchini", "brie", "cherry_tomatoes"],
                price: eur(900)
            },
            {
                name: "Carbonara",
                ingredients: ["tomato", "mozzarella", "smoked_bacon", "eggs", "grana"],
                price: eur(900)
            },
            {
                name: "Estiva",
                ingredients: ["tomato", "mozzarella", "mozzarella_di_bufala", "cherry_tomatoes", "rocket_salad"],
                price: eur(900)
            },
            {
                name: "Braccio di Ferro",
                ingredients: ["tomato", "mozzarella", "spinach", "ricotta", "speck"],
                price: eur(900)
            },
            {
                name: "Saporita",
                ingredients: ["tomato", "mozzarella", "nuts", "brie", "speck"],
                price: eur(900)
            },
            {
                name: "Affumicata",
                ingredients: ["tomato", "mozzarella", "smoked_scamorza", "smoked_bacon"],
                price: eur(900)
            },
            {
                name: "Alpina",
                ingredients: ["tomato", "mozzarella", "porcino_mushrooms", "gorgonzola"],
                price: eur(900)
            },
            {
                name: "Rustica",
                ingredients: ["tomato", "mozzarella", "peppers", "sausage", "gorgonzola"],
                price: eur(900)
            },
            {
                name: "Maialata",
                ingredients: ["tomato", "mozzarella", "cooked_ham", "sausage", "spicy_salami", "würstel"],
                price: eur(900)
            },
            {
                name: "Gustosa",
                ingredients: ["tomato", "mozzarella", "peas", "cherry_tomatoes", "shrimps"],
                price: eur(900)
            },
            {
                name: "Boscaiola",
                ingredients: ["tomato", "mozzarella", "mixed_mushrooms"],
                price: eur(900)
            }
        ]
    },
    {
        name: defineMessage({id: "house_pizzas", defaultMessage: "Pizze della casa"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Tega",
                ingredients: ["tomato", "mozzarella", "würstel", "porcino_mushrooms", "gorgonzola", "spicy_salami"],
                price: eur(950)
            },
            {
                name: "Gigi",
                ingredients: ["tomato", "mozzarella", "brie", "zucchini", "rocket_salad", "bresaola"],
                price: eur(950)
            },
            {
                name: "Gimmy",
                ingredients: ["tomato", "mozzarella", "peppers", "sausage", "roast_pork", "smoked_scamorza"],
                price: eur(950)
            },
            {
                name: "Valeria",
                ingredients: ["tomato", "mozzarella", "smoked_bacon", "honey_mushrooms", "gorgonzola", "dark_olives"],
                price: eur(950)
            },
            {
                name: "Jessica",
                ingredients: ["tomato", "mozzarella", "artichokes", "cherry_tomatoes", "grilled_ham", "mozzarella_di_bufala"],
                price: eur(950)
            },
            {
                name: "Barbara",
                ingredients: ["tomato", "mozzarella", "eggplant", "porcino_mushrooms", "smoked_scamorza", "raw_ham"],
                price: eur(950)
            },
            {
                name: "Ketty",
                ingredients: ["tomato", "mozzarella", "radicchio", "sausage", "brie", "speck"],
                price: eur(950)
            },
            {
                name: "Greta",
                ingredients: ["tomato", "mozzarella", "smoked_bacon", "cherry_tomatoes", "asparagus", "eggs"],
                price: eur(950)
            },
            {
                name: "Francesco",
                ingredients: ["tomato", "mozzarella", "tuna", "onion", "dark_olives", "grilled_ham"],
                price: eur(950)
            },
            {
                name: "May",
                ingredients: ["tomato", "mozzarella", "cherry_tomatoes", "grilled_ham", "mozzarella_di_bufala"],
                price: eur(950)
            },
            {
                name: "Debora",
                ingredients: ["tomato", "mozzarella", "sausage", "zucchini", "grana", "asiago"],
                price: eur(950)
            },
            {
                name: "Romeo",
                ingredients: ["tomato", "mozzarella", "taggiasca_olives", "mozzarella_di_bufala", "cherry_tomatoes", "tuna"],
                price: eur(950)
            },
            {
                name: "Renzino",
                ingredients: ["tomato", "mozzarella", "smoked_bacon", "gorgonzola", "radicchio"],
                price: eur(950)
            },
            {
                name: "Vera",
                ingredients: ["tomato", "mozzarella", "asiago", "bacon", "peas", "honey_mushrooms"],
                price: eur(950)
            },
            {
                name: "Dino",
                ingredients: ["tomato", "mozzarella", "mushrooms", "cooked_ham", "artichokes", "dark_olives", "spicy_salami"],
                price: eur(950)
            },
            {
                name: "Gabriella",
                ingredients: ["tomato", "mozzarella", "sliced_horse", "cherry_tomatoes", "grana", "rocket_salad"],
                price: eur(950)
            },
            {
                name: "Roberto",
                ingredients: ["tomato", "mozzarella", "peppers", "spicy_salami", "bacon", "gorgonzola", "taggiasca_olives"],
                price: eur(950)
            },
            {
                name: "Camilla",
                ingredients: ["tomato", "mozzarella", "brie", "spicy_salami", "cherry_tomatoes", "taggiasca_olives"],
                price: eur(950)
            },
            {
                name: "Silvia",
                ingredients: ["tomato", "mozzarella", "cherry_tomatoes", "rocket_salad", "raw_ham", "burrata"],
                price: eur(950)
            },
            {
                name: "L'Alternativa",
                ingredients: ["fantasy_of_pizza_maker"],
                price: eur(950)
            },
        ],
    },
    {
        name: defineMessage({id: "white_pizzas", defaultMessage: "Pizze bianche"}),
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Bianca Uno",
                ingredients: ["mozzarella", "tropea_onion", "spicy_salami", "gorgonzola"],
                price: eur(850)
            },
            {
                name: "Bianca Due",
                ingredients: ["mozzarella", "dried_tomatoes", "mozzarella_di_bufala"],
                price: eur(850)
            },
            {
                name: "Bianca Tre",
                ingredients: ["mozzarella", "shrimps", "rocket_salad", "peas"],
                price: eur(850)
            },
            {
                name: "Bianca Quattro",
                ingredients: ["mozzarella", "porcino_mushrooms", "grilled_ham", "mozzarella_di_bufala"],
                price: eur(850)
            },
            {
                name: "Bianca Cinque",
                ingredients: ["mozzarella", "cherry_tomatoes", "raw_ham", "mozzarella_di_bufala"],
                price: eur(850)
            },
            {
                name: "Bianca Sei",
                ingredients: ["mozzarella", "raw_ham", "rocket_salad", "grana"],
                price: eur(850)
            },
            {
                name: "Bianca Sette",
                ingredients: ["mozzarella", "sausage", "friarielli", "grana"],
                price: eur(850)
            },
            {
                name: "Bianca Otto",
                ingredients: ["mozzarella", "peppers", "corn", "brie", "spicy_salami"],
                price: eur(850)
            },
            {
                name: "Bianca Nove",
                ingredients: ["mozzarella", "zucchini", "nuts", "grilled_ham", "asiago"],
                price: eur(850)
            },
            {
                name: "Bianca Dieci",
                ingredients: ["mozzarella", "peppers", "honey_mushrooms", "gorgonzola", "turkey"],
                price: eur(850)
            },
            {
                name: "Bianca Undici",
                ingredients: ["mozzarella", "cream", "cooked_ham", "peas"],
                price: eur(850)
            },
        ]
    },
    {
        name: "Pizze gorde rosse",
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Gorda rossa Uno",
                ingredients: ["tomato", "garlic", "oregano", "anchovies", "burrata"],
                price: eur(950)
            },
            {
                name: "Gorda rossa Due",
                ingredients: ["tomato", "mozzarella_di_bufala", "pistachio_pesto", "grilled_ham"],
                price: eur(950)
            },
            {
                name: "Gorda rossa Tre",
                ingredients: ["tomato", "burrata", "raw_ham"],
                price: eur(950)
            },
            {
                name: "Gorda rossa Quattro",
                ingredients: ["tomato", "pistachio_pesto", "grana"],
                price: eur(950)
            },
        ],
    },
    {
        name: "Pizze gorde bianche",
        subtitle: SUBTITLE,
        entries: [
            {
                name: "Gorda bianca Uno",
                ingredients: ["mozzarella", "pistachio_pesto", "mortadella", "burrata"],
                price: eur(950)
            },
            {
                name: "Gorda bianca Due",
                ingredients: ["mozzarella", "dried_tomatoes", "pistachio_pesto"],
                price: eur(950)
            },
            {
                name: "Gorda bianca Tre",
                ingredients: ["mozzarella", "cherry_tomatoes", "pistachio_pesto", "burrata"],
                price: eur(950)
            },
            {
                name: "Gorda bianca Quattro",
                ingredients: ["mozzarella", "pistachio_pesto", "burrata", "french_fires"],
                price: eur(950)
            },
            {
                name: "Gorda bianca Cinque",
                ingredients: ["mozzarella", "dried_tomatoes", "pistachio_pesto", "burrata", "sausage"],
                price: eur(950)
            },
            {
                name: "Gorda bianca Sei",
                ingredients: ["mozzarella", "onion_rings", "sausage", "gorgonzola"],
                price: eur(950)
            },
        ]
    },
    {
        name: "La più gorda",
        subtitle: SUBTITLE,
        entries: [{
            name: "Maialino rosa",
            ingredients: ["tomato", "mozzarella", "pulled_pork", "onion", "peppers", "asiago", "country_fries"],
            price: eur(1200)
        }]
    },
    {
        name: defineMessage({id: "calzones", defaultMessage: "Calzoni"}),
        subtitle: <EachAddition price={eur(150)}/>,
        entries: [
            {
                name: "Numero Uno",
                ingredients: ["tomato", "mozzarella", "cooked_ham", "mushrooms"],
                price: eur(850)
            },
            {
                name: "Numero Due",
                ingredients: ["tomato", "mozzarella", "peppers", "zucchini", "eggplant"],
                price: eur(850)
            },
            {
                name: "Numero Tre",
                ingredients: ["tomato", "mozzarella", "würstel"],
                price: eur(850)
            },
            {
                name: "Numero Quattro",
                ingredients: ["tomato", "mozzarella", "gorgonzola", "brie", "smoked_scamorza"],
                price: eur(850)
            },
            {
                name: "Numero Cinque",
                ingredients: ["tomato", "mozzarella", "zucchini", "sausage", "gorgonzola"],
                price: eur(850)
            },
        ]
    },
    {
        name: defineMessage({id: "white_flatbread_pizzas", defaultMessage: "Schiacciate"}),
        entries: [
            {
                name: "Schiacciata",
                ingredients: ["extra_virgin_olive_oil", "oregano"],
                price: eur(400)
            },
            {
                name: "Schiacciata Uno",
                ingredients: ["extra_virgin_olive_oil", "oregano", "onion", "peppers", "brie", "cooked_ham"],
                price: eur(800)
            },
            {
                name: "Schiacciata Due",
                ingredients: ["extra_virgin_olive_oil", "oregano", "zucchini", "eggplant", "peppers", "gorgonzola"],
                price: eur(800)
            },
            {
                name: "Schiacciata Tre",
                ingredients: ["extra_virgin_olive_oil", "oregano", "corn", "brie", "cooked_ham", "zucchini"],
                price: eur(800)
            },
            {
                name: "Schiacciata Quattro",
                ingredients: ["extra_virgin_olive_oil", "oregano", "bacon", "zucchini", "gorgonzola"],
                price: eur(800)
            },
            {
                name: "Schiacciata Cinque",
                ingredients: [
                    "extra_virgin_olive_oil",
                    "oregano",
                    "tomato_sauce",
                    {text: afterCookingIngredient("mozzarella_di_bufala")}
                ],
                price: eur(600)
            },
            {
                name: "Schiacciata Sei",
                ingredients: [
                    "extra_virgin_olive_oil",
                    "oregano",
                    "eggplant",
                    "cooked_ham",
                    "brie",
                    {text: afterCookingIngredient("tomato_sauce")}
                ],
                price: eur(800)
            },
            {
                name: "Schiacciata Sette",
                ingredients: ["extra_virgin_olive_oil", "oregano", "mortadella", "burrata", "pistachio_pesto", "chopped_pistachios"],
                price: eur(800)
            },
            {
                name: "Schiacciata Otto",
                ingredients: [
                    "extra_virgin_olive_oil",
                    "oregano",
                    "raw_ham",
                    {
                        text: intl => intl.formatList([
                            formatIngredient(intl, "burrata"),
                            formatIngredient(intl, "mozzarella_di_bufala"),
                        ], {type: "disjunction"})
                    }
                ],
                price: eur(800)
            },
            {
                name: "Schiacciata Nove",
                ingredients: ["extra_virgin_olive_oil", "oregano", "eggplant", "zucchini", "peppers"],
                price: eur(800)
            },
        ]
    },
    {
        name: defineMessage({id: "family_size_pizza", defaultMessage: "Pizza famiglia"}),
        subtitle: <EachAddition price={eur(200)}/>,
        entries: [
            {
                name: "Margherita",
                ingredients: ["tomato", "mozzarella"],
                price: eur(1400)
            }
        ]
    },
]

export default function Pizze() {
    return <MenuCategoryPage>
        <MenuEntriesView subCategories={ITEMS}/>
    </MenuCategoryPage>
}