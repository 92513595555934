import {FancyBottledBeer} from "../../../datastruct/Beers";
import morettiGrandCru from "../../../images/moretti-grand-cru.png";
import morettiGraniAntichi from "../../../images/moretti-grani-antichi.png";
import morettiLungaMaturazione from "../../../images/moretti-lunga-maturazione.png";
import CategoryView from "../../../components/CategoryView";
import React from "react";
import FancyBottledBeers from "../../../components/beers/FancyBottledBeers";
import {defineMessage} from "react-intl";
import {volumeFormatter} from "../../../intl/intl-utils";
import {eur} from "../../../utils";

const BEERS: FancyBottledBeer[] = [
    {
        name: "Grand Cru",
        image: morettiGrandCru as unknown as ResponsiveImageOutput,
        description: defineMessage({
            id: "beer.grand_cru.description",
            defaultMessage: "Birra Ale ad alta fermentazione di colore ambrato, con riflessi aranciati e dal gusto raffinato"
        }),
        alcoholContent: 0.068,
        price: {
            description: volumeFormatter(750),
            price: eur(900)
        }
    },
    {
        name: "Grani Antichi",
        image: morettiGraniAntichi as unknown as ResponsiveImageOutput,
        description: defineMessage({
            id: "beer.grani_antichi.description",
            defaultMessage: "Birra ad alta fermentazione di colore ambrato e con gusto caldo ed equilibrato"
        }),
        alcoholContent: 0.08,
        price: {
            description: volumeFormatter(750),
            price: eur(900)
        }
    },
    {
        name: "Lunga Maturazione",
        image: morettiLungaMaturazione as unknown as ResponsiveImageOutput,
        description: defineMessage({
            id: "beer.lunga_maturazione.description",
            defaultMessage: "Birra ad alta fermentazione di colore dorato, limpido. Note morbide e fresche che anticipano una lieve chiusura amara"
        }),
        alcoholContent: 0.07,
        price: {
            description: volumeFormatter(750),
            price: eur(900)
        }
    },
]

export default function ReserveBeers() {
    return <CategoryView
        name={defineMessage({id: "moretti_reserve_selection", defaultMessage: "Selezione riserva Moretti"})}
        children={<FancyBottledBeers beers={BEERS}/>}
    />
}