import React, {PropsWithChildren} from "react";
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import {FormattedMessage} from "react-intl";

export default function MenuCategoryPage(props: PropsWithChildren<{}>) {
    const history = useHistory()
    return <>
        <Button onClick={() => history.push("/menu")}>
            &lt;&nbsp;<FormattedMessage
                id="back_action"
                defaultMessage="Indietro"
            />
        </Button>
        {props.children}
    </>
}