import {isValidElement, ReactElement} from "react";
import {IntlShape, MessageDescriptor, useIntl} from "react-intl";


export type IntlTextType = string | MessageDescriptor | ((intl: IntlShape) => string)

export default function IntlText({text}: { text: IntlTextType | ReactElement }) {
    const intl = useIntl()
    if (isValidElement(text)) {
        return text
    } else {
        return <>{resolveIntlText(intl, text)}</>
    }
}

export function resolveIntlText(intl: IntlShape, text: IntlTextType) {
    if (typeof text === "function") {
        return text(intl)
    } else if (typeof text === "string") {
        return text
    } else {
        return intl.formatMessage(text)
    }
}

export function useIntlText(text: IntlTextType) {
    const intl = useIntl()
    return resolveIntlText(intl, text)
}