import React, {CSSProperties} from "react";
import MenuCategoryButton from "../components/MenuCategoryButton";
import {MenuCategory} from "../datastruct/MenuEntries";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import Pizze from "./menu/Pizze";
import PaniniEPiadine from "./menu/PaniniEPiadine";
import Antipasti from "./menu/Antipasti";
import Bevande from "./menu/Bevande";
import Bar from "./menu/Bar";
import Dolci from "./menu/Dolci";
import Piatti from "./menu/Piatti";
import Sandwich from "./menu/Sandwich";
import Hamburger from "./menu/Hamburger";
import Birre from "./menu/Birre";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useTitle} from "../utils";
import PriceView from "../components/price/PriceView";
import Money from "js-money";
import css from "./MenuPage.module.css"
import BoxedWarning from "../components/BoxedWarning";
import RestaurantIcon from '@material-ui/icons/Restaurant';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import TollIcon from '@material-ui/icons/Toll';
import {defineMessage, FormattedMessage, useIntl} from "react-intl";
import {useIntlText} from "../intl/IntlText";

export const MENU_CATEGORIES: MenuCategory[] = [
    {
        path: "birre",
        title: defineMessage({id: "beers", defaultMessage: "Birre"}),
        image: 'birre.jpg',
        component: Birre,
        objectPosition: "top"
    },
    {
        path: "bevande",
        title: defineMessage({id: "drinks", defaultMessage: "Bevande"}),
        image: 'bevande.jpg',
        component: Bevande
    },
    {
        path: "antipasti",
        title: defineMessage({id: "appetizers", defaultMessage: "Antipasti"}),
        image: 'fritti.jpg',
        component: Antipasti
    },
    {
        path: "pizze",
        title: defineMessage({id: "pizzas", defaultMessage: "Pizze"}),
        image: 'pizza.jpg',
        component: Pizze
    },
    {
        path: "sandwich",
        title: defineMessage({id: "sandwiches", defaultMessage: "Sandwich"}),
        image: 'sandwich.jpg',
        component: Sandwich
    },
    {
        path: "panini-e-piadine",
        title: defineMessage({id: "paninos_and_piadinas", defaultMessage: "Panini e piadine"}),
        image: 'panini.jpg',
        component: PaniniEPiadine
    },
    {
        path: "hamburger",
        title: defineMessage({id: "hamburgers", defaultMessage: "Hamburger"}),
        image: 'hamburger.jpg',
        component: Hamburger
    },
    {
        path: "piatti",
        title: defineMessage({id: "dishes", defaultMessage: "Piatti"}),
        image: 'piatti.jpg',
        component: Piatti
    },
    {
        path: "dolci",
        title: defineMessage({id: "desserts", defaultMessage: "Dolci"}),
        image: 'dolci.jpg',
        component: Dolci
    },
    {
        path: "bar",
        title: defineMessage({id: "bar", defaultMessage: "Bar"}),
        image: 'bar.jpg',
        component: Bar
    },
]


function Component() {
    const breakpoint = useBreakpoint()
    const gridStyle: CSSProperties = {
        display: "grid",
        gridGap: "16px"
    }
    let variant: "square" | "rectangle" = "square"
    if (breakpoint["xl"]) {
        gridStyle.gridTemplateColumns = "repeat(4, 1fr)"
        variant = "rectangle"
    } else if (breakpoint["lg"]) {
        gridStyle.gridTemplateColumns = "repeat(5, 1fr)"
    } else if (breakpoint["md"]) {
        gridStyle.gridTemplateColumns = "repeat(4, 1fr)"
    } else if (breakpoint["sm"]) {
        gridStyle.gridTemplateColumns = "repeat(3, 1fr)"
    } else if (breakpoint["xs"]) {
        gridStyle.gridTemplateColumns = "repeat(2, 1fr)"
    }

    return <>
        <div style={gridStyle}>
            {MENU_CATEGORIES.map(category => {
                return <MenuCategoryButton key={category.path} category={category} variant={variant}/>
            })}
        </div>
        <div className={css.boxes}>
            <BoxedWarning icon={<RestaurantIcon/>}>
                <FormattedMessage
                    id="allergies_notice"
                    defaultMessage="Se hai allergie o intolleranze alimentari, chiedici informazioni sul nostro cibo e sulle nostre bevande."
                />
            </BoxedWarning>
            <BoxedWarning icon={<AcUnitIcon/>}>
                <FormattedMessage
                    id="frozen_notice"
                    defaultMessage="Avvisiamo la clientela che i prodotti che friggiamo, gli hamburger e le cotolette possono essere surgelati o provenienti da congelazione tramite abbattitore."
                />
            </BoxedWarning>
            <BoxedWarning icon={<TollIcon/>}>
                <FormattedMessage
                    id="cover_charge_notice"
                    defaultMessage="Costo coperto {price}"
                    values={{price: <PriceView price={new Money(150, Money.EUR)}/>}}
                />
            </BoxedWarning>
        </div>
    </>
}

function Category({category}: { category: MenuCategory }) {
    useTitle(useIntlText(category.title))
    return <>
        {category.component && <category.component/>}
    </>
}

export default function MenuPage() {
    const intl = useIntl()
    useTitle(intl.formatMessage({id: "menu", defaultMessage: "Menu"}))
    const match = useRouteMatch()

    return <Switch>
        <Route path={match.url} exact>
            <Component/>
        </Route>

        {MENU_CATEGORIES.map(category => {
            return <Route key={category.path} path={`${match.url}/${category.path}`}>
                <Category category={category}/>
            </Route>
        })}
    </Switch>
}