import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import React, {ReactElement} from "react";
import css from './ItemRow.module.css'
import {Divider} from "antd";
import PriceOrPricesView from "./price/PriceOrPricesView";
import {PriceOrPrices} from "../datastruct/Price";
import IntlText, {IntlTextType} from "../intl/IntlText";


export default function ItemRow(props: {
    title: IntlTextType|ReactElement
    description?: IntlTextType|ReactElement
    price?: PriceOrPrices
    divider?: boolean
}) {
    const divider = props.divider === undefined ? true : props.divider

    let price
    if (props.price) {
        price = <PriceOrPricesView price={props.price} className={css.price}/>
    } else {
        price = null
    }

    return <div className={css.root}>
        <div className={css.row}>
            <div className={css.info}>
                <span translate="no">
                    <Title className={css.title} level={5}>
                        <IntlText text={props.title}/>
                    </Title>
                </span>
                {props.description && <Text>
                    <IntlText text={props.description}/>
                </Text>}
            </div>
            {price}
        </div>
        <div className={css.bottomSpace}/>
        {divider && <Divider className={css.divider}/>}
    </div>
}